import "./styles/App.css";
import "./styles/View.css";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Events from "./pages/Events";
import Media from "./pages/Media";
import Profile from "./pages/Profile";
import { Link, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ViewProducts from "./subpages/ViewProducts";
import PurchaseProducts from "./subpages/PurchaseProducts";
import Albums from "./subpages/Albums";
import DownloadMedia from "./subpages/DownloadMedia";
import ViewSuccess from "./subpages/ViewSuccess";
import ErrorProvider from "./pages/ErrorProvider";
import ViewEvents from "./subpages/ViewEvents";
import Background from "./subpages/Background";
import ExploreSpace from "./subpages/ExploreSpace";
import SecuritySupport from "./subpages/SecuritySupport";
import GetStart from "./subpages/GetStart";
import NewsPanel from "./subpages/NewsPanel";
import ReadNews from "./subpages/ReadNews";
import ForgotPass from "./pages/ForgotPass";
import QRshare from "./subpages/QRshare";
import Ratings from "./subpages/Ratings";
import AdminDashboard from "./admin/AdminDashboard";
import Varify from "./pages/Varify";
import DownloadZip from "./subpages/DownloadZip";

function App() {
  const [bigging, setBigging] = useState(true);
  const [Error, setError] = useState("load");
  const [ErrorTitle, setErrorTitle] = useState("Loading Dspace");
  const [ErrorTime, setErrorTime] = useState(5000);
  const [ErrorActions, setErrorActions] = useState("none");
  const [ErrorAction, setErrorAction] = useState("");
  const [ErrorMode, setErrorMode] = useState("normal");
  const [ErrorMessage, setErrorMessage] = useState(
    "Please wait few seconds..."
  );
  const [share, setShare] = useState("");
  const [from, setFrom] = useState("");
  const [login, setLogin] = useState(false);
  const [userData, setUserData] = useState([]);
  const server_url = "https://app.dspace.icu";

  useEffect(() => {
    if (localStorage.getItem("dsp-login-status")) {
      setLogin(localStorage.getItem("dsp-login-status"));
      if (localStorage.getItem("login")) {
        setBigging(false);
      } else {
        localStorage.setItem("login", "signin");
      }
    } else {
      localStorage.setItem("dsp-login-status", false);
      localStorage.setItem("dsp-theme", false);
      localStorage.setItem("login", "signin");
    }

    if (localStorage.getItem("dsp-theme") === "true") {
      document.getElementById("theme").click();
    }
  }, [bigging]);

  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to={"/home"}>
              <i className="fa fa-home fa-gradient"></i>
            </Link>
          </li>
          <li>
            <Link to={"/products"}>
              <i className="fa fa-rocket fa-gradient"></i>
            </Link>
          </li>
          <li>
            <Link to={"/media"}>
              <i className="fa fa-images fa-gradient"></i>
            </Link>
          </li>

          <li>
            <Link to={"/events"}>
              <i className="fa fa-trophy fa-gradient"></i>
            </Link>
          </li>
          <li>
            <Link to={"/profile"}>
              {login !== "true" ? (
                <i className="fa fa-sign-in fa-gradient"></i>
              ) : (
                <i className="fa fa-skull fa-gradient"></i>
              )}
            </Link>
          </li>
        </ul>
      </nav>
      {Error.length > 0 ? (
        <ErrorProvider
          error={Error}
          setError={setError}
          time={ErrorTime}
          title={ErrorTitle}
          actions={ErrorActions}
          setErrorTime={setErrorTime}
          setErrorAction={setErrorAction}
          message={ErrorMessage}
          mode={ErrorMode}
          setmode={setErrorMode}
        />
      ) : null}
      <Background />
      {share !== "" ? (
        <QRshare
          server={server_url}
          addr={share}
          setShare={setShare}
          from={from}
        />
      ) : null}
      <Routes>
        <Route path="/" element={<Home server={server_url} />} />
        <Route
          path="/varify"
          element={
            <Varify
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route path="/home" element={<Home server={server_url} />} />
        <Route path="/products" element={<Products server={server_url} />} />
        <Route
          path="/rate"
          element={
            <Ratings
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route path="/media" element={<Media server={server_url} />} />
        <Route
          path="/profile"
          element={
            <Profile
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setLogin={setLogin}
              login={login}
              setUserData={setUserData}
              userData={userData}
            />
          }
        />
        <Route path="/events" element={<Events server={server_url} />} />
        <Route
          path="/view_products"
          element={
            <ViewProducts
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setShare={setShare}
              setFrom={setFrom}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/terms_n_conditions"
          element={
            <SecuritySupport
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
            />
          }
        />
        <Route
          path="/purchase_products"
          element={
            <PurchaseProducts
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setShare={setShare}
              setFrom={setFrom}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/download_zip"
          element={
            <DownloadZip
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route
          path="/view_albums"
          element={
            <Albums
              server={server_url}
              setShare={setShare}
              setFrom={setFrom}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/forgot_password"
          element={
            <ForgotPass
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route
          path="/download_media"
          element={
            <DownloadMedia
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/view_events"
          element={
            <ViewEvents
              setShare={setShare}
              server={server_url}
              setFrom={setFrom}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route path="/news_area" element={<NewsPanel server={server_url} />} />
        <Route
          path="/admin_dashboard"
          element={
            <AdminDashboard
              setmode={setErrorMode}
              server={server_url}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route path="/read_news" element={<ReadNews server={server_url} />} />
        <Route path="/explore" element={<ExploreSpace server={server_url} />} />
        <Route
          path="/get_start"
          element={
            <GetStart
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/view_success"
          element={
            <ViewSuccess
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
      </Routes>
    </>
  );
}

export default App;
