import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import moment from "moment";
import { v4 as uuidV4 } from "uuid";
import axios from "axios";

function Security_n_support({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) {
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  const [mode, setMode] = useState("terms");
  const [username, setUsername] = useState(user.user);
  const [question, setQuestion] = useState("");
  const [table_faq, setTable_faq] = useState([]);
  const [reps, setreps] = useState([]);
  const [date, setDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD @ hh:mm a")
  );
  const [begin, setbegin] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    if (urlParams.get("cookie_policy") === "true") {
      setMode("cookie policy");
      urlParams.set("cookie_policy", " ");
    }
    axios.get(server + "/qa?id=" + user.uid).then((response) => {
      if (response.data.status === "success") {
        setTable_faq(response.data.data);
      }
    });
    setbegin(false);
  }, [begin]);
  const deleteQuestion = (val) => {
    const data = {
      id: val,
    };
    axios.post(server + "/qa_delete", data).then((response) => {
      if (response.data.status === "success") {
        setTable_faq(table_faq.filter((item) => item.id !== val));

        axios.post(server + "/ans_delete", data).then((response) => {
          if (response.data.status === "success") {
            MsgShow(
              "success",
              "success",
              50,
              "ok",
              "Your question has been deleted successfully. You can ask about anything anytime =)",
              "Question deleted"
            );
            axios.get(server + "/qa?id=" + user.uid).then((response) => {
              if (response.data.status === "success") {
                setTable_faq(response.data.data);
              }
            });
          }
        });
      }
    });
  };
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const table_security = [
    {
      id: 1,
      title: "Introduction",
      details:
        "Welcome to Dspace.lk. By accessing our website, you agree to comply with and be bound by the following terms and conditions.",
    },
    {
      id: 2,
      title: "Intellectual Property Rights",
      details:
        "Unless otherwise stated, Dspace.lk and/or its licensors own the intellectual property rights for all material on Dspace.lk. All intellectual property rights are reserved.",
    },
    {
      id: 3,
      title: "User Content",
      details:
        "In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material, and audio-visual material) that you submit to this website, for whatever purpose. You grant to Dspace.lk a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media.",
    },
    {
      id: 4,
      title: "Acceptable Use",
      details:
        "You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of Dspace.lk. You must not use this website in any way which is unlawful, illegal, fraudulent, or harmful.",
    },
    {
      id: 5,
      title: "Limitation of Liability",
      details:
        "Dspace.lkwill not be liable for any indirect, special, or consequential loss or damage arising out of or in connection with the use of our website.",
    },
    {
      id: 6,
      title: "Indemnity",
      details:
        "You hereby indemnify Dspace.lk and undertake to keep Dspace.lk indemnified against any losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by Dspace.lk to a third party in settlement of a claim or dispute) incurred or suffered by Dspace.lk arising out of any breach by you of any provision of these terms and conditions.",
    },
    {
      id: 7,
      title: "Variation of Terms",
      details:
        "Dspace.lk is permitted to revise these terms and conditions at any time as it sees fit, and by using this website you are expected to review these terms on a regular basis.",
    },
    {
      id: 8,
      title: "Governing Law",
      details:
        "These terms and conditions are governed by and construed in accordance with the laws of [Your Country], and you irrevocably submit to the exclusive jurisdiction of the courts in that location.",
    },
  ];
  const table_privacy = [
    {
      id: 1,
      title: "Introduction",
      details:
        "Dspace.lk is committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our website.",
    },
    {
      id: 2,
      title: "Information Collection and Use",
      details:
        "We collect several types of information for various purposes to provide and improve our services to you. This includes:\n\n■ Personal Data: \nName, email address, phone number, etc., that you provide to us.\n\n■ Usage Data: \nInformation on how you access and use our website, including IP address, browser type, pages visited, and the time spent on those pages.",
    },
    {
      id: 3,
      title: "Use of Data",
      details:
        "Dspace.lk uses the collected data for various purposes:\n\n■ To provide and maintain our service\n\n■ To notify you about changes to our service\n\n■ To provide customer support\n\n■ To monitor the usage of our service\n\n■ To detect, prevent, and address technical issues",
    },
    {
      id: 4,
      title: "Data Sharing and Disclosure",
      details:
        "We do not sell, trade, or otherwise transfer your personal data to outside parties, except as required by law. We may share information with trusted third-party service providers who assist us in operating our website, conducting our business, or serving our users.",
    },
    {
      id: 5,
      title: "Security of Data",
      details:
        "We take reasonable measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure.",
    },
    {
      id: 6,
      title: "Your Data Protection Rights",
      details:
        "Depending on your location, you may have the following rights:\n\n■ The right to access: You can request copies of your personal data.\n\n■ The right to rectification: You can request that we correct any information you believe is inaccurate.\n\n■ The right to erasure: You can request that we erase your personal data under certain conditions.\n\n■ The right to restrict processing: You can request that we restrict the processing of your personal data under certain conditions.\n\n■ The right to object to processing: You can object to our processing of your personal data under certain conditions.\n\n■ The right to data portability: You can request that we transfer the data we have collected to another organization or directly to you under certain conditions.",
    },
    {
      id: 7,
      title: "Changes to This Privacy Policy",
      details:
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
    },
    {
      id: 8,
      title: "Contact Us",
      details:
        "If you have any questions about this Privacy Policy, please contact us:\n\n■ By email: mail@dspace.lk\n\n■ By visiting this page on our website: https://dspace.lk/faq\n\n■ By phone number: +94768463836",
    },
  ];
  const table_cookie = [
    {
      id: 1,
      title: "Introduction",
      details:
        "This Cookie Policy explains how Dspace.lkuses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control their use.",
    },
    {
      id: 2,
      title: "What are Cookies?",
      details:
        "Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.",
    },
    {
      id: 3,
      title: "Why Do We Use Cookies?",
      details: `We use first and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for advertising, analytics, and other purposes.`,
    },
    {
      id: 4,
      title: "Types of Cookies We Use",
      details:
        "Essential Cookies: These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.\n\nPerformance and Functionality Cookies: These cookies enhance the performance and functionality of our website but are non-essential to its use. However, without these cookies, certain functionality (like videos) may become unavailable.\n\nAnalytics and Customization Cookies: These cookies collect information that is used either in aggregate form to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you.\n\nAdvertising Cookies: These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting advertisements that are based on your interests.",
    },
    {
      id: 5,
      title: "Your Choices Regarding Cookies",
      details:
        "You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by setting or amending your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.",
    },
    {
      id: 6,
      title: "Changes to This Cookie Policy",
      details:
        "We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.",
    },
    {
      id: 7,
      title: "Contact Us",
      details:
        "If you have any questions about our use of cookies or other technologies, please email us at mail@dspace.lk.",
    },
  ];
  const showReps = (e) => {
    axios.get(server + "/reply?id=" + e).then((response) => {
      if (response.data.status === "success") {
        setreps(response.data.data);
      }
    });
  };
  const submitAction = () => {
    if (username.length > 0) {
      if (question.length > 0) {
        const data = {
          user: username,
          ques: question,
          date: date,
          uid: user.uid,
          fqid: uuidV4(),
        };
        axios.post(server + "/qa", data).then((response) => {
          if (response.data.status === "success") {
            setQuestion("");
            clearF();
            MsgShow(
              "success",
              "success",
              50,
              "ok",
              "Your question has been submitted successfully. I will reply to you soon as possible.",
              "Question added"
            );
            setbegin(true);
          }
        });
      }
    }
  };
  function clearF() {
    let form = document.getElementById("myForm");
    form.reset();
  }
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/home"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Terms & Conditions</h2>
          </div>
          <i class="fa fa-shield"></i>
        </div>
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              placeholder={`Search in ${mode}`}
            />

            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="line-bar ">
          <div className="line-back"></div>

          <div className="line cntr maxw">
            <div
              className="btn-wrap"
              onClick={() => {
                setMode("terms");
              }}
            >
              <div className="btn">
                <i class="fa fa-shield"></i>
              </div>
            </div>

            <div
              className="btn-wrap"
              onClick={() => {
                setMode("privacy");
              }}
            >
              <div className="btn">
                <i class="fa fa-user-lock"></i>
              </div>
            </div>
            <div
              className="btn-wrap"
              onClick={() => {
                setMode("cookie policy");
              }}
            >
              <div className="btn">
                <i class="fa fa-cookie"></i>
              </div>
            </div>
            <div
              className="btn-wrap"
              onClick={() => {
                setMode("faq");
              }}
            >
              <div className="btn">
                <i class="fa fa-question"></i>
              </div>
            </div>
          </div>
        </div>
        {mode === "terms" ? (
          <div className="table-bar">
            <div className="table-cr">
              {table_security.map((val) => {
                return (
                  <div className="table-row" key={val.id}>
                    <div className="title">{val.title}</div>
                    <div className="details">
                      <p>{val.details}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {mode === "privacy" ? (
          <div className="table-bar">
            <div className="table-cr">
              {table_privacy.map((val) => {
                return (
                  <div className="table-row" key={val.id}>
                    <div className="title">{val.title}</div>
                    <div className="details">
                      <p>{val.details}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {mode === "cookie policy" ? (
          <div className="table-bar">
            <div className="table-cr">
              {table_cookie.map((val) => {
                return (
                  <div className="table-row" key={val.id}>
                    <div className="title">{val.title}</div>
                    <div className="details">
                      <p>{val.details}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {mode === "faq" ? (
          <div className="post">
            <form action="" method="post" id="myForm">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>Tell us about it breifly</h4>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-user"></i>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name"
                    value={user.user}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input txta">
                  <i class="fa fa-question"></i>
                  <textarea
                    name="details"
                    id="details"
                    placeholder="Tell us your question short & sweet."
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                  />
                </div>
              </div>
              <p>Please wait few workng hours to get a reply.</p>
              <div className="btn-bar">
                <div
                  className="btn-bx"
                  onClick={() => {
                    submitAction();
                  }}
                >
                  <i className="fa fa-comment"></i>
                  submit
                </div>
              </div>
            </form>
          </div>
        ) : null}
        {mode === "faq" ? (
          <div className="table-bar">
            <h4>Your Questions</h4>
            <div className="table-cr">
              {table_faq.map((val) => {
                return (
                  <>
                    <div className="table-row" key={val.id}>
                      <div className="title">
                        <p className="btn-cir-bar">
                          <span
                            className="btn-cir"
                            onClick={() => {
                              if (reps.length > 0) {
                                setreps([]);
                              } else {
                                showReps(val.fqid);
                              }
                            }}
                          >
                            <i className="fa fa-comments"></i>
                            {val.reps}
                          </span>
                          <span
                            className="btn-cir"
                            onClick={() => {
                              deleteQuestion(val.fqid);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </span>
                        </p>
                      </div>
                      <div className="details">
                        <p>ASKED : {val.date}</p>
                        <br />
                        <p>{val.ques}</p>
                        <br />
                        <span>By : {val.user}</span>
                      </div>
                    </div>
                    {reps.map((rep) => {
                      if (rep.qaid === val.fqid)
                        return (
                          <div className="table-row" key={rep.id}>
                            <div className="title"></div>
                            <div className="details">
                              <p>REPLIED : {rep.date}</p>
                              <br />
                              <p>{rep.ans}</p>
                              <br />
                              <span>By : {rep.user}</span>
                            </div>
                          </div>
                        );
                    })}
                  </>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

export default Security_n_support;
