import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Img from "../assets/duruthu.png";

const AddEvents = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [begin, setBegin] = useState(true);
  const [Add, setAdd] = useState(false);
  const [Del, setDel] = useState(false);
  const [title, setTitle] = useState("");
  const [Organizer, setOrganizer] = useState("");
  const [photo, setPhoto] = useState("");
  const [text, setText] = useState("");
  const [events, setEvents] = useState([]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/events").then((response) => {
      if (response.data.status === "success") {
        setEvents(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setP(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function clearF() {
    let form = document.getElementById("formcd");
    form.reset();
  }
  function setP(e) {
    setPhoto(e);
  }
  const upNews = () => {
    if (title.length > 0) {
      if (text.length > 0) {
        if (photo.length > 0) {
          const data = {
            title: title,
            name: Organizer,
            text: text,
            photo: photo,
            date: moment(Date.now()).format("YYYY-MM-DD"),
          };

          axios.post(server + "/add_event", data).then((response) => {
            if (response.data.status === "success") {
              MsgShow(
                "success",
                "success",
                5000,
                "ok",
                `You have successfully added the event. Your event will be shown in Events section.`,
                "Event Recorded."
              );
              setTitle("");
              setText("");
              setPhoto("");
              clearF();
              setBegin(true);
            }
          });
        }
      }
    }
  };
  return (
    <>
      <div className="photo-bar">
        {events.length > 0
          ? events.map((event) => {
              return (
                <div className="photo-card" key={event.id}>
                  <div className="image">
                    <img src={event.pic} alt="" />
                    {Del ? (
                      <div
                        className="del-btn"
                        onClick={() => {
                          const data = {
                            id: event.id,
                          };
                          axios
                            .post(server + "/delete_event", data)
                            .then((response) => {
                              if (response.data.status === "success") {
                                MsgShow(
                                  "success",
                                  "success",
                                  5000,
                                  "ok",
                                  `You have successfully deleted the Event. Your event will be removed from Events section.`,
                                  "Event Deleted"
                                );
                                axios
                                  .get(server + "/events")
                                  .then((response) => {
                                    if (response.data.status === "success") {
                                      setEvents(response.data.data);
                                    }
                                  });
                              }
                            });
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </div>
                    ) : null}
                  </div>
                  <div className="text-panel">
                    <h4>@{event.name}</h4>
                  </div>
                </div>
              );
            })
          : null}
        <div
          className="photo-card"
          onClick={() => {
            if (Add) {
              setAdd(false);
            } else {
              setAdd(true);
            }
          }}
        >
          <div className="image">
            <div className="text-panel">
              <i
                className="fa fa-plus-square"
                style={{ fontSize: "2rem", marginBottom: "10px" }}
              ></i>
              <h4>Event</h4>
            </div>
          </div>
        </div>
      </div>
      {Add ? (
        <form action="" method="post" id="formcd">
          <div className="news-cover">
            <img
              src={photo ? photo : Img}
              alt=""
              onClick={() => {
                document.getElementById("upload").click();
              }}
            />
          </div>
          <h4>Fill Details To Add News Item</h4>
          <input
            type="file"
            name="upload"
            id="upload"
            onChange={(e) => {
              for (var i = 0; i < e.target.files.length; i++) {
                getBase64(e.target.files[i]);
              }
            }}
          />
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-at"></i>
              <input
                type="text"
                name="title"
                id="name"
                placeholder="Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-at"></i>
              <input
                type="text"
                name="title"
                id="name"
                placeholder="Organizer"
                onChange={(e) => {
                  setOrganizer(e.target.value);
                }}
              />
            </div>
          </div>
          <div class="flex-bar">
            <div class="input txta">
              <i class="fa fa-flash"></i>
              <textarea
                name="details"
                id="details"
                placeholder="Event Description"
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="btn-bar">
            <div
              className="btn-bx"
              onClick={() => {
                upNews();
              }}
            >
              <i className="fa fa-plus"></i>
              Add Event
            </div>
            <div
              className="btn-bx close"
              onClick={() => {
                if (Add) {
                  setAdd(false);
                } else {
                  setAdd(true);
                }
              }}
            >
              <i className="fa fa-close"></i>
              Cancel Progress
            </div>
          </div>
        </form>
      ) : (
        <div className="btn-bar">
          <div
            className="btn-bx no"
            onClick={() => {
              if (Del) {
                setDel(false);
              } else {
                setDel(true);
              }
            }}
          >
            <i className="fa fa-trash"></i>
            Delete
          </div>

          <div className="btn-bx close" onClick={() => setShow("")}>
            <i className="fa fa-close"></i>
            Close
          </div>
        </div>
      )}
    </>
  );
};

export default AddEvents;
