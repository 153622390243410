import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Img from "../assets/duruthu.png";
import moment from "moment";

const AddNews = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [begin, setBegin] = useState(true);
  const [Add, setAdd] = useState(false);
  const [Del, setDel] = useState(false);
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState("");
  const [text, setText] = useState("");
  const [news, setNews] = useState([]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  useEffect(() => {
    axios.get(server + "/get_news").then((response) => {
      if (response.data.status === "success") {
        setNews(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setP(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function clearF() {
    let form = document.getElementById("formcd");
    form.reset();
  }
  function setP(e) {
    setPhoto(e);
  }
  const upNews = () => {
    if (title.length > 0) {
      if (text.length > 0) {
        if (photo.length > 0) {
          const data = {
            title: title,
            text: text,
            photo: photo,
            date: moment(Date.now()).format("YYYY-MM-DD"),
          };

          axios.post(server + "/add_news", data).then((response) => {
            if (response.data.status === "success") {
              MsgShow(
                "success",
                "success",
                5000,
                "ok",
                `You have successfully added the news item. Your news will be shown in NEWS section.`,
                "News Submitted"
              );
              setTitle("");
              setText("");
              setPhoto("");
              clearF();
              setBegin(true);
            }
          });
        }
      }
    }
  };
  return (
    <>
      <div className="grid-bar center-bar">
        {news.map((newsItem) => {
          return (
            <div key={newsItem.id} className="news_card dnews">
              <div className="news_img">
                <img
                  src={newsItem.covr.length > 0 ? newsItem.covr : Img}
                  alt=""
                />
              </div>
              <div className="news_text">
                <div className="hd">
                  <h4>{newsItem.title}</h4>
                </div>
                <p>
                  {newsItem.text.substring(0, 150)}...
                  <br />
                  <br />
                  <Link to={"/read_news?id=" + newsItem.id} className="see_opt">
                    See More <i className="fa fa-chevron-right"></i>
                  </Link>
                </p>
                {Del ? (
                  <div
                    className="del-btn"
                    onClick={() => {
                      const data = {
                        id: newsItem.id,
                      };
                      axios
                        .post(server + "/delete_news", data)
                        .then((response) => {
                          if (response.data.status === "success") {
                            MsgShow(
                              "success",
                              "success",
                              5000,
                              "ok",
                              `You have successfully deleted the news item. Your news will be removed from NEWS section.`,
                              "News Deleted"
                            );
                            setBegin(true);
                          }
                        });
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      {Add ? (
        <form action="" method="post" id="formcd">
          <div className="news-cover">
            <img
              src={photo ? photo : Img}
              alt=""
              onClick={() => {
                document.getElementById("upload").click();
              }}
            />
          </div>
          <h4>Fill Details To Add News Item</h4>
          <input
            type="file"
            name="upload"
            id="upload"
            onChange={(e) => {
              for (var i = 0; i < e.target.files.length; i++) {
                getBase64(e.target.files[i]);
              }
            }}
          />
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-at"></i>
              <input
                type="text"
                name="title"
                id="name"
                placeholder="Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div class="flex-bar">
            <div class="input txta">
              <i class="fa fa-flash"></i>
              <textarea
                name="details"
                id="details"
                placeholder="News Description"
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="btn-bar">
            <div
              className="btn-bx"
              onClick={() => {
                upNews();
              }}
            >
              <i className="fa fa-plus"></i>
              Add News Item
            </div>
            <div
              className="btn-bx close"
              onClick={() => {
                if (Add) {
                  setAdd(false);
                } else {
                  setAdd(true);
                }
              }}
            >
              <i className="fa fa-close"></i>
              Cancel Progress
            </div>
          </div>
        </form>
      ) : (
        <div className="btn-bar">
          <div
            className="btn-bx no"
            onClick={() => {
              if (Del) {
                setDel(false);
              } else {
                setDel(true);
              }
            }}
          >
            <i className="fa fa-trash"></i>
            Delete
          </div>
          <div
            className="btn-bx"
            onClick={() => {
              if (Add) {
                setAdd(false);
              } else {
                setAdd(true);
              }
            }}
          >
            <i className="fa fa-plus"></i>
            Add New
          </div>
          <div className="btn-bx close" onClick={() => setShow("")}>
            <i className="fa fa-close"></i>
            Close
          </div>
        </div>
      )}
    </>
  );
};

export default AddNews;
