import React, { useState } from "react";
import Sign from "./Sign";
import User from "./User";

function Profile({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  login,
  setLogin,
  userData,
  setUserData,
  server,
}) {
  return (
    <>
      {localStorage.getItem("dsp-login-status") !== "true" ? (
        <Sign
          setmode={setmode}
          setError={setError}
          setErrorTime={setErrorTime}
          setErrorActions={setErrorActions}
          setErrorMessage={setErrorMessage}
          setErrorTitle={setErrorTitle}
          setLogins={setLogin}
          setUserData={setUserData}
          server={server}
        />
      ) : (
        <User
          setLogin={setLogin}
          userData={userData}
          login={login}
          setmode={setmode}
          setError={setError}
          setErrorTime={setErrorTime}
          setErrorActions={setErrorActions}
          setErrorMessage={setErrorMessage}
          setErrorTitle={setErrorTitle}
          server={server}
        />
      )}
      ;
    </>
  );
}
export default Profile;
