import React, { act, useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import AddAlbums from "./AddAlbums";
import AddMedia from "./AddMedia";
import AddNews from "./AddNews";
import AddEvents from "./AddEvents";
import AddProducts from "./AddProducts";
import ReplyFAQ from "./ReplyFAQ";
import RecoverFiles from "./RecoverFiles";

const AdminDashboard = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [show, setShow] = useState("");
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <i
            class="fa fa-dashboard cur-po"
            onClick={() => {
              setShow("");
            }}
          ></i>
          <div class="textline">
            <h2>Admin Dashboard</h2>
          </div>
          <i
            className="fa fa-sign-out cur-po"
            onClick={() => {
              window.history.back();
            }}
          ></i>
        </div>

        <div className="dash-grid">
          {show === "add_albums" ? (
            <AddAlbums
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_media" ? (
            <AddMedia
              setmode={setmode}
              server={server}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_news" ? (
            <AddNews
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_events" ? (
            <AddEvents
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_products" ? (
            <AddProducts
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "reply_faqs" ? (
            <ReplyFAQ
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "recover_files" ? (
            <RecoverFiles
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : (
            <div className="btns">
              <div className="grid-btn" onClick={() => setShow("add_media")}>
                <i className="fa fa-images"></i>
                <p>Add Media</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_news")}>
                <i className="fa fa-newspaper"></i>
                <p>Add News</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_events")}>
                <i className="fa fa-calendar"></i>
                <p>Add Events</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_products")}>
                <i className="fa fa-rocket"></i>
                <p>Add Products</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("reply_faqs")}>
                <i className="fas fa-comment"></i>
                <p>Reply FAQs</p>
              </div>
              <div
                className="grid-btn"
                onClick={() => setShow("recover_files")}
              >
                <i className="fas fa-folder"></i>
                <p>Recover Files</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
