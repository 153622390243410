import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer";
import axios from "axios";

const NewsPanel = ({ server }) => {
  const [begin, setBegin] = useState(true);
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get(server + "/get_news").then((response) => {
      if (response.data.status === "success") {
        setNews(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/explore"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>News Area</h2>
          </div>

          <i class="fa fa-newspaper"></i>
        </div>
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search anything here..."
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="grid-bar center-bar">
          {news.map((newsItem) => {
            return (
              <div key={newsItem.id} className="news_card dnews">
                <div className="news_img">
                  <img src={newsItem.covr} alt="" />
                </div>
                <div className="news_text">
                  <div className="hd">
                    <h4>{newsItem.title}</h4>
                  </div>
                  <p>
                    {newsItem.text.substring(0, 150)}...
                    <br />
                    <br />
                    <Link
                      to={"/read_news?id=" + newsItem.id}
                      className="see_opt"
                    >
                      See More <i className="fa fa-chevron-right"></i>
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div class="tags-bar">
          <ul>
            <li>
              <i class="fa fa-tag"></i>Audios
            </li>
            <li>
              <i class="fa fa-tag"></i>Videos
            </li>
            <li>
              <i class="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i class="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i class="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default NewsPanel;
