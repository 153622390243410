import React, { useState } from "react";
import Footer from "./Footer";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { v4 as uuidV4 } from "uuid";

const Varify = ({
  server,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
}) => {
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  const [varify, setVarify] = useState(false);
  const [Otpmail, setOtpmail] = useState(
    localStorage.getItem("for-email").replace(`"`, "").replace(`"`, "")
  );
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [nic, setNic] = useState("");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const ClearForm = () => {
    let form = document.getElementById("formc");
    form.reset();
  };
  const emailHide = (email) => {
    let one = email.split("@")[0];
    let two = email.split("@")[1];
    let tw1 = two.split(".")[0];
    let three = two.split(".")[1];

    var hiddenOne = "";
    for (var i = 0; i < one.length; i++) {
      if (i > 2 && i < one.length) {
        hiddenOne += "*";
      } else {
        hiddenOne += one[i];
      }
    }
    var hiddenTwo = "";
    for (var x = 0; x < tw1.length; x++) {
      if (x < tw1.length) {
        hiddenTwo += "*";
      } else {
        hiddenTwo += two[x];
      }
    }

    let newStr = hiddenOne + "@" + hiddenTwo + "." + three;
    return newStr;
  };
  if (user.varified === "YES") return null;
  else {
    return (
      <div className="flow-wall">
        <div className="scroll-wall">
          <div className="varific">
            <div className="var-card">
              {varify ? (
                <div className="text">
                  <form method="POST" id="formc">
                    <div className="icon">
                      <i></i>
                    </div>
                    <h4>Enter OTP Code</h4>
                    <div className="flex-bar">
                      <div className="input">
                        <i className="fa fa-shield"></i>
                        <input
                          type="text"
                          name="otp"
                          maxLength={6}
                          placeholder="OTP CODE"
                          id="name"
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex-bar">
                      <div className="input">
                        <i className="fa fa-id-card"></i>
                        <input
                          type="text"
                          name="nic"
                          maxLength={12}
                          placeholder="NIC Number"
                          id="name"
                          required
                          onChange={(e) => {
                            setNic(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="btn-bar">
                      <div
                        className="btn-bx yes"
                        style={{ outline: "none", border: "none" }}
                        id="submit"
                        onClick={() => {
                          if (nic.length > 9) {
                            if (otp.length > 0) {
                              const data = {
                                email: Otpmail,
                                otp: otp,
                              };
                              axios
                                .post(server + "/get_varify", data)
                                .then((response) => {
                                  if (response.data.status === "success") {
                                    const data2 = {
                                      email: user.email,
                                      nic: nic,
                                    };
                                    axios
                                      .post(server + "/update_nic", data2)
                                      .then((response2) => {
                                        if (
                                          response2.data.status === "success"
                                        ) {
                                          MsgShow(
                                            "success",
                                            "success",
                                            5000,
                                            "ok",
                                            `Your account has been verified. Now you can feel free to explore Dspace.`,
                                            "Account Verified"
                                          );
                                          ClearForm();
                                          window.location.href = "/profile";
                                        }
                                      });
                                  }
                                });
                            }
                          } else {
                            MsgShow(
                              "error",
                              "warning",
                              5000,
                              "close",
                              `Your N.I.C Number seems to like wrong! or Not Valid. Please update your N.I.C Number.`,
                              "NIC Not Valid"
                            );
                          }
                        }}
                      >
                        <i className="fa fa-check"></i>
                        Varify
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="text">
                  <form method="POST" id="formc">
                    <div className="icon">
                      <i></i>
                    </div>
                    <h4>Varification Required!</h4>
                    <p>
                      <span>
                        Enter your following Email Address to start varification
                        process <br />
                        {emailHide(Otpmail)}
                      </span>
                    </p>
                    <br />
                    <div className="flex-bar">
                      <div className="input">
                        <i className="fa fa-at"></i>
                        <input
                          type="email"
                          name="email"
                          placeholder={emailHide(Otpmail)}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="btn-bar">
                      <div
                        className="btn-bx yes"
                        onClick={() => {
                          if (email.length > 0) {
                            if (email === Otpmail) {
                              const otp = Math.round(
                                Math.floor(100000 + Math.random() * 999999)
                              );
                              const data = {
                                email: email,
                                otp: otp,
                              };
                              axios
                                .post(server + "/forgot_mail", data)
                                .then((response) => {
                                  if (response.data.status === "success") {
                                    emailjs.init({
                                      publicKey: "00YXxLX6rNDtuAHhT",
                                    });
                                    var template_params = {
                                      to_name: email,
                                      code: otp,
                                      id: uuidV4(),
                                    };
                                    emailjs
                                      .send(
                                        "service_o5xumnk",
                                        "template_hke3jjy",
                                        template_params
                                      )
                                      .then(
                                        (response) => {
                                          MsgShow(
                                            "success",
                                            "success",
                                            5000,
                                            "ok",
                                            `We have sent an OTP to your email. Please check your inbox.`,
                                            "OTP Sent"
                                          );
                                          setVarify(true);
                                          ClearForm();
                                        },
                                        (error) => {
                                          MsgShow(
                                            "error",
                                            "warning",
                                            5000,
                                            "close",
                                            `${error}`,
                                            "OTP Sent Failed"
                                          );
                                        }
                                      );
                                  }
                                });
                            }
                          }
                        }}
                      >
                        <i className="fa fa-send"></i>
                        Send OTP
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
};
export default Varify;
