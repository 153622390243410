import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { v4 as uuidV4 } from "uuid";

function Sign({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setLogins,
  setUserData,
  server,
}) {
  const [login, setLogin] = useState("");
  const [bigging, setBigging] = useState(true);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [passC, setPassC] = useState("");
  const [checkB, setCheckB] = useState(false);

  useEffect(() => {
    setLogin(localStorage.getItem("login"));
    setBigging(false);
  }, [bigging]);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const signIn = () => {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (user.length > 5) {
      if (pass.length > 7) {
        if (pass.match(passw)) {
          if (checkB === true) {
            //code goes here for sign in

            //stringify data
            var data = {
              user: user,
              pass: pass,
            };
            axios.post(server + "/signin", data).then((response) => {
              // handle success
              if (response.data.status === "success") {
                setUserData(response.data.data);
                localStorage.setItem("dsp-login-status", "true");
                localStorage.setItem(
                  "dsp-user",
                  JSON.stringify(response.data.data[0])
                );
                localStorage.setItem(
                  "for-email",
                  JSON.stringify(response.data.data[0].email)
                );
                setLogins("true");
                MsgShow(
                  "success",
                  "success",
                  50,
                  "close",
                  "You have signed in successfully. Feel free to surf in Dspace. Make a safe journey.",
                  "User Signed In"
                );
                setPass("");
                setPassC("");
                setEmail("");
                setUser("");
                clearF();
              } else {
                MsgShow(
                  "error",
                  "warning",
                  50,
                  "close",
                  response.data.msg,
                  "Ooops..."
                );
                setPass("");
                setPassC("");
                setEmail("");
                setUser("");
                clearF();
              }
            });
          } else {
            //code goes here for sign in
          }
        } else {
          MsgShow(
            "error",
            "warning",
            50,
            "close",
            "Not a valid password. We are highly recomend you to use a password which contains\nA Cap Letter\nA Simple Letter\nA Special Character\nAnd A Number.",
            "Password not valid"
          );
        }
      } else {
        MsgShow(
          "error",
          "warning",
          50,
          "close",
          "Not a valid password. We are highly recomend you to use a password between 8 to 16 characters long.",
          "Password not valid"
        );
      }
    } else {
      MsgShow(
        "error",
        "warning",
        50,
        "close",
        "Not a valid username. We are highly recomend you to use a username between 5 to 10 characters long.",
        "Username not valid"
      );
    }
  };
  const signUp = () => {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (user.length > 5) {
      if (email.length > 0) {
        if (email.match(mailformat)) {
          if (pass.length > 7) {
            if (pass.match(passw)) {
              if (passC === pass) {
                if (checkB === true) {
                  //code goes here for sign in
                  let theme = "";
                  if (localStorage.getItem("dsp-theme") === "true") {
                    theme = "true";
                  } else {
                    theme = "false";
                  }
                  //stringify data
                  var data = {
                    user: user,
                    email: email,
                    pass: pass,
                    date: moment(Date.now()).format("MMM Do YY"),
                    uid: uuidV4(),
                    varified: "none",
                    theme: theme,
                  };
                  axios.post(server + "/signup", data).then((response) => {
                    // handle success
                    console.log(response);
                    if (response.data.status === "success") {
                      localStorage.setItem("login", "signin");
                      setLogin("signin");
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "close",
                        "You have registered successfully. Please sign in to Feel free in Dspace. Make a safe journey.",
                        "User Registered"
                      );
                      setPass("");
                      setPassC("");
                      setEmail("");
                      setUser("");
                      clearF();
                    } else {
                      setLogin("signup");
                      MsgShow(
                        "error",
                        "warning",
                        50,
                        "close",
                        response.data.msg,
                        "Ooops..."
                      );
                    }
                  });
                } else {
                  MsgShow(
                    "error",
                    "warning",
                    50,
                    "close",
                    "You have to accept our terms & conditions for register Dspace. Otherwise your request for joining will be declined.",
                    "Accept our terms & conditions."
                  );
                }
              } else {
                MsgShow(
                  "error",
                  "warning",
                  50,
                  "close",
                  "Password doesn't match to the confirmation password. Please use same password twice to sign up.",
                  "Password not matched"
                );
              }
            } else {
              MsgShow(
                "error",
                "warning",
                50,
                "close",
                "Not a valid password. We are highly recomend you to use a password which contains\nA Cap Letter\nA Simple Letter\nA Special Character\nAnd A Number.",
                "Password not valid"
              );
            }
          } else {
            MsgShow(
              "error",
              "warning",
              50,
              "close",
              "Not a valid password. We are highly recomend you to use a password between 8 to 16 characters long.",
              "Password not valid"
            );
          }
        } else {
          MsgShow(
            "error",
            "warning",
            50,
            "close",
            "Not a valid email address. It seems like your email address is not in correct format or you have entered unauthorized characters.",
            "Email not valid"
          );
        }
      } else {
        MsgShow(
          "error",
          "warning",
          50,
          "close",
          "Not a valid email address. We are highly recomend you to use a working and correct email address to sign up.",
          "Email not valid"
        );
      }
    } else {
      MsgShow(
        "error",
        "warning",
        50,
        "close",
        "Not a valid username. We are highly recomend you to use a username between 5 to 10 characters long.",
        "Username not valid"
      );
    }
  };

  function clearF() {
    let form = document.getElementById("formc");
    form.reset();
  }
  return (
    <div className="wall">
      <div className="scroll-wall">
        {login === "signin" ? (
          <div class="post">
            <form action="" method="post" id="formc">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>sign in</h4>
              <p>Please use your cridentials to sign in.</p>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-at"></i>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUser(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-lock"></i>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPass(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar wrap-bar">
                <div class="remember">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    onChange={(e) => {
                      setCheckB(e.target.checked);
                    }}
                  />
                  <label for="remember">
                    <p>Remember me</p>
                  </label>
                </div>
                <div class="forgot">
                  <i className="fa fa-lock"></i>
                  <Link to="/forgot_password">Forgot Password?</Link>
                </div>
              </div>

              <div class="btn-bar">
                <div
                  class="btn-bx"
                  onClick={() => {
                    signIn();
                  }}
                >
                  <i class="fa fa-sign-in"></i> sign in
                </div>
                <div
                  class="btn-bx cancel"
                  onClick={() => {
                    setLogin("signup");
                    localStorage.setItem("login", "signup");
                  }}
                >
                  <i class="fa fa-link"></i> Sign up
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div class="post">
            <form action="" method="post" id="formc">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>sign up</h4>
              <p>Please use valid details to register.</p>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-user"></i>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUser(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-at"></i>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setEmail(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-lock"></i>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPass(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-lock"></i>
                  <input
                    type="password"
                    name="password"
                    id="password-c"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPassC(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="remember">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    onChange={(e) => {
                      setCheckB(e.target.checked);
                    }}
                  />
                  <label for="remember">
                    <p>I accept to terms & conditions.</p>
                  </label>
                </div>
              </div>

              <div class="btn-bar">
                <div
                  class="btn-bx"
                  onClick={() => {
                    signUp();
                  }}
                >
                  <i class="fa fa-link"></i> sign up
                </div>
                <div
                  class="btn-bx cancel"
                  onClick={() => {
                    setLogin("signin");
                    localStorage.setItem("login", "signin");
                  }}
                >
                  <i class="fa fa-sign-in"></i> Sign in
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
export default Sign;
