import React, { useEffect, useState } from "react";
import Icon from "../assets/icon.png";
import axios from "axios";

const DownloadProduct = ({
  view,
  setViewItem,
  setShare,
  setFrom,
  pur,
  server,
}) => {
  const [begin, setBegin] = useState(true);
  const [Item, setItem] = useState([]);

  useEffect(() => {
    axios.get(server + "/get_pr?id=" + view).then((response) => {
      if (response.data.status === "success") {
        setItem(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);

  return (
    <div className="event-view">
      {Item
        ? Item.map((file) => {
            return (
              <div className="event-bar">
                <div className="title">
                  <div className="icon">
                    <img src={file.file !== "" ? file.icon : Icon} alt="" />
                  </div>
                </div>
                <div className="details">
                  <p>
                    <h4>{file.name}</h4>
                  </p>
                  <p>
                    <span> {file.subt}</span>
                  </p>
                  <p>
                    <span>Size :</span> {file.size}
                  </p>
                  <p>
                    <span>OS Version :</span> {file.osvr}
                  </p>
                  <p>
                    <span>Req RAM :</span> {file.rams} GB
                  </p>
                  <p>
                    <span>Req V.RAM :</span> {file.rams} MB
                  </p>
                  <p>
                    <span>Version :</span> {file.prvr}
                  </p>
                </div>

                <div className="btn-bar">
                  <div className="btn-bx yes">
                    <i className="fa fa-download"></i>
                    get
                  </div>
                  <div
                    className="btn-bx"
                    onClick={() => {
                      if (pur === "pur") {
                        setShare(
                          "https://www.dspace.icu/purchase_products?id=" +
                            file.id
                        );
                        setFrom("products");
                      } else {
                        setShare(
                          "https://www.dspace.icu/view_products?id=" + file.id
                        );
                        setFrom("products");
                      }
                    }}
                  >
                    <i className="fa fa-link"></i>
                    Share
                  </div>

                  <div
                    className="btn-bx close"
                    onClick={() => {
                      const queryString = window.location.search;
                      const urlParams = new URLSearchParams(queryString);
                      const product = urlParams.get("id");
                      if (product) {
                        window.history.back();
                        setViewItem("");
                      } else {
                        setViewItem("");
                      }
                    }}
                  >
                    <i className="fa fa-close"></i>
                    close
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default DownloadProduct;
