import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import JSZip, { forEach } from "jszip";
import { v4 as uuidV4 } from "uuid";
import FileSaver from "file-saver";

const DownloadZip = ({ server }) => {
  const [beginDown, setbeginDown] = useState(false);
  const [doneGen, setdoneGen] = useState(false);
  const files = JSON.parse(sessionStorage.getItem("files"));
  let file_size = 0;
  for (let i = 0; i < files.length; i++) {
    file_size += files[i].size;
  }
  var sizeInMB = (file_size / (1024 * 1024)).toFixed(2);
  const file_name = sessionStorage.getItem("zip-name");
  function enableButn(value) {
    setbeginDown(true);
    // Converting string to required date format
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + value);
    // To call defined fuction every second
    let x = setInterval(function () {
      // Getting current time in required format
      let now = new Date().getTime();

      // Calculating the difference
      let t = deadline - now;

      // Getting value of days, hours, minutes, seconds
      let seconds = Math.floor((t % (1000 * 60)) / 1000);

      // Output the remaining time
      document.getElementById("reduced").innerHTML =
        "Please wait " +
        (seconds > 9
          ? seconds + " seconds to create the download link"
          : "0" + seconds + " seconds to create the download link");
      document.getElementById("pgr").style.width =
        Math.floor(((value - seconds) / value) * 100) + "%";
      // Output for over time
      if (t < 0) {
        clearInterval(x);
        setdoneGen(true);
        document.getElementById("reduced").innerHTML =
          "Your download link has been generated successfully. Click the below button to download.";
      }
    }, 1000);
  }
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <div
            className="back-btn"
            onClick={() => {
              window.history.back();
            }}
          >
            <i className="fa fa-chevron-circle-left"></i>
          </div>
          <div class="textline">
            <h2>Download Zip</h2>
          </div>
          <i class="fa fa-archive"></i>
        </div>
        <div className="varific">
          <div className="var-card">
            <div className="text">
              <form id="formc">
                <div className="icon">
                  <i></i>
                </div>
                {doneGen ? (
                  <div className="zip-info">
                    <h4>
                      Zip Name : <span>{file_name}</span>
                    </h4>
                    <p>
                      Size : <span>{sizeInMB ? sizeInMB + " MB" : null}</span>
                    </p>
                    <p>
                      Gen ID : <span>GEN-{uuidV4()}</span>
                    </p>
                  </div>
                ) : (
                  <div className="down-bar">
                    <div className="pg-bar">
                      <div className="pg" id="pgr"></div>
                    </div>
                    <p id="reduced">Click to generate the link</p>
                  </div>
                )}
                <div className="btn-bar">
                  {doneGen ? (
                    <div
                      className="btn-bx"
                      onClick={() => {
                        const zip = new JSZip();
                        files.forEach((file) => {
                          zip.file(file.name, file.file);
                        });
                        zip
                          .generateAsync({ type: "blob" })
                          .then(function (content) {
                            FileSaver.saveAs(content, file_name);
                            sessionStorage.removeItem("files");
                            sessionStorage.removeItem("zip-name");
                            window.history.back();
                          });
                      }}
                    >
                      <i className="fa fa-download"></i>
                      Download
                    </div>
                  ) : (
                    <>
                      <div
                        className="btn-bx yes"
                        onClick={() => {
                          enableButn(17);
                        }}
                      >
                        <i className="fa fa-archive"></i>
                        Generate
                      </div>
                      <div
                        className="btn-bx close"
                        onClick={() => {
                          sessionStorage.removeItem("files");
                          sessionStorage.removeItem("zip-name");
                          window.history.back();
                        }}
                      >
                        <i className="fa fa-close"></i>
                        Close
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default DownloadZip;
