import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function Media() {
  return (
    <div className="wall">
      <div class="post">
        <div class="logo">
          <img src="DLM.png" alt="Logo" />
        </div>
        <h4>Media Albums & Contents</h4>
        <p>
          At Dspace.lk, we specialize in developing cutting-edge multimedia
          services to bring your digital dreams to life. From stunning web
          designs to robust software solutions, our team of experts is dedicated
          to delivering innovative, high-quality products tailored to meet your
          unique needs. Join us on this journey to digital excellence.
        </p>
        <div class="btn-bar">
          <Link class="btn-bx" to={"/view_albums"}>
            <i class="fa fa-images"></i> Explore
          </Link>
          <Link class="btn-bx" to={"/download_media"}>
            <i class="fa fa-download"></i> Download
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Media;
