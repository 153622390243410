import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer";
import Img from "../assets/duruthu.png";
import axios from "axios";

const ReadNews = ({ server }) => {
  const [begin, setBegin] = useState(true);
  const [news, setNews] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("id");
  useEffect(() => {
    axios.get(server + "/get_news").then((response) => {
      if (response.data.status === "success") {
        setNews(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/news_area"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Article</h2>
          </div>

          <i class="fa fa-newspaper"></i>
        </div>
        {news.map((item) => {
          return (
            <div className="news-wall">
              <div className="news-img">
                <img src={item.covr} alt="" />
              </div>
              <div className="news-title">
                <h4>{item.title}</h4>
              </div>
              <div className="news-text">
                <p>{item.text}</p>
              </div>
              <div className="line-bar">
                <div className="line-back"></div>
                <div
                  className="line"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <div className="btn-wrap" onClick={() => {}}>
                    <div className="btn">
                      <i class="fa fa-thumbs-up"></i>
                    </div>
                  </div>

                  <div className="btn-wrap" onClick={() => {}}>
                    <div className="btn">
                      <i class="fa fa-thumbs-down fa-flip-horizontal"></i>
                    </div>
                  </div>
                  <div className="btn-wrap" onClick={() => {}}>
                    <div className="btn">
                      <i class="fa fa-link "></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div class="tags-bar">
          <ul>
            <li>
              <i class="fa fa-tag"></i>Audios
            </li>
            <li>
              <i class="fa fa-tag"></i>Videos
            </li>
            <li>
              <i class="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i class="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i class="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ReadNews;
