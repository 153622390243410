import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/video.gif";
import Icon2 from "../assets/audio.gif";
import { Link } from "react-router-dom";
import ViewAlbumMedia from "./ViewAlbumMedia";
import ViewMedia from "./ViewMedia";
import axios from "axios";

const Albums = ({
  setShare,
  setFrom,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [show, setShow] = useState("photo");
  const [album, setAlbum] = useState("");
  const [files, setFiles] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [begin, setBegin] = useState(true);
  const [viewFile, setViewFile] = useState("");
  const [category, setCategory] = useState("");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/albums").then((response) => {
      if (response.data.status === "success") {
        setAlbums(response.data.data);
      }
    });
    axios.get(server + "/videos").then((response) => {
      if (response.data.status === "success") {
        setVideos(response.data.data);
      }
    });
    axios.get(server + "/audios").then((response) => {
      if (response.data.status === "success") {
        setAudios(response.data.data);
      }
    });
    setBegin(false);
    MsgShow(
      "normal",
      "load",
      5000,
      "",
      "Please wait while we load our media.",
      "Loading media"
    );
  }, [begin]);

  return (
    <div class="flow-wall">
      <div class="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/media"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <ul className="selector">
            <li
              onLoad={() => {
                if (show === "photo") {
                  document.querySelector(".photo").classList.add("active");
                }
              }}
              className="photo active"
              onClick={() => {
                setShow("photo");
                setAlbum("");
                setViewFile("");
                setCategory("");
                document.querySelector(".photo").classList.add("active");
                document.querySelector(".video").classList.remove("active");
                document.querySelector(".audio").classList.remove("active");
                document.querySelector(".table").classList.remove("active");
              }}
            >
              <i className="fa fa-photo"></i>
            </li>
            <li
              className="video"
              onClick={() => {
                setShow("video");
                setAlbum("");
                setViewFile("");
                setCategory("");
                document.querySelector(".photo").classList.remove("active");
                document.querySelector(".video").classList.add("active");
                document.querySelector(".audio").classList.remove("active");
                document.querySelector(".table").classList.remove("active");
              }}
            >
              <i className="fa fa-film"></i>
            </li>
            <li
              className="audio"
              onClick={() => {
                setShow("audio");
                setAlbum("");
                setViewFile("");
                setCategory("");
                document.querySelector(".photo").classList.remove("active");
                document.querySelector(".video").classList.remove("active");
                document.querySelector(".audio").classList.add("active");
                document.querySelector(".table").classList.remove("active");
              }}
            >
              <i className="fa fa-headphones"></i>
            </li>
          </ul>
        </div>
        {viewFile !== "" ? (
          <ViewMedia
            server={server}
            icon={Icon2}
            view={viewFile}
            setShare={setShare}
            setFrom={setFrom}
            setViewFile={setViewFile}
            setCategory={setCategory}
            category={category}
          />
        ) : null}
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search anything here..."
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        {album !== "" ? (
          <ViewAlbumMedia
            server={server}
            icon={Icon}
            album={album}
            setCategory={setCategory}
            show={show}
            setview={setViewFile}
          />
        ) : show === "photo" ? (
          <div className="photo-bar">
            {albums
              ? albums.map((album) => {
                  return (
                    <div
                      className="photo-card"
                      key={album.id}
                      onClick={() => {
                        if (album === album.fid) {
                          setAlbum("");
                        } else {
                          setAlbum(album.fid);
                        }
                      }}
                    >
                      <div className="image">
                        <img src={album.fp} alt="" />
                      </div>
                      <div className="text-panel">
                        <h4>{album.name}</h4>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : show === "video" ? (
          <div className="photo-bar">
            {videos
              ? videos.map((video) => {
                  return (
                    <div
                      className="photo-card"
                      key={video.id}
                      onClick={() => {
                        if (album === video.vid) {
                          setViewFile("");
                          setCategory("");
                        } else {
                          setViewFile(video.vid);
                          setCategory("video");
                        }
                      }}
                    >
                      <div className="image">
                        <img src={Icon} alt="" />
                      </div>
                      <div className="text-panel">
                        <h4>{video.name.substring(0, 10)}...</h4>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : show === "audio" ? (
          <div className="photo-bar">
            {audios
              ? audios.map((audio) => {
                  return (
                    <div
                      className="photo-card"
                      key={audio.id}
                      onClick={() => {
                        if (album === audio.aid) {
                          setViewFile("");
                          setCategory("");
                        } else {
                          setViewFile(audio.aid);
                          setCategory("audio");
                        }
                      }}
                    >
                      <div className="image">
                        <img src={Icon2} alt="" />
                      </div>
                      <div className="text-panel">
                        <h4>{audio.name}</h4>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}

        <div class="body-txt">
          {show === "photo" ? (
            <p>
              At Dspace.lk, we believe in the power of photography to tell
              stories and preserve memories. Our photo albums are meticulously
              organized to provide an easy and enjoyable browsing experience.
              Take a moment to explore and cherish the moments captured in our
              albums. Thank you for visiting Dspace.lk Photo Albums. Enjoy the
              journey through our visual stories.
            </p>
          ) : show === "video" ? (
            <p>
              At dspace.lk, we believe in the power of videos to tell stories
              and evoke emotions. Our video albums are carefully organized to
              ensure an immersive and enjoyable viewing experience. Take a
              moment to explore and be inspired by the stories captured in
              motion. Thank you for visiting dspace.lkVideo Albums. Enjoy the
              journey through our visual narratives.
            </p>
          ) : show === "audio" ? (
            <p>
              At dspace.lk, we believe in the power of sound to tell stories,
              evoke emotions, and connect people. Our audio albums are
              meticulously organized to provide an enjoyable listening
              experience. Take a moment to explore and indulge in the auditory
              delights we have to offer. Thank you for visiting dspace.lkAudio
              Albums. Enjoy the journey through our world of sound.
            </p>
          ) : null}
        </div>
        <div class="tags-bar">
          <ul>
            <li>
              <i class="fa fa-tag"></i>Audios
            </li>
            <li>
              <i class="fa fa-tag"></i>Videos
            </li>
            <li>
              <i class="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i class="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i class="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Albums;
