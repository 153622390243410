import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import DownloadProduct from "./DownloadProduct";
import axios from "axios";

const PurchaseProducts = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setFrom,
  setShare,
  server,
}) => {
  const [products, setProducts] = useState([]);
  const [purItem, setPurItem] = useState("");
  const [begin, setBegin] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("id");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  useEffect(() => {
    axios.get(server + "/products").then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
        MsgShow(
          "normal",
          "load",
          2500,
          "",
          "Please Wait...",
          "Dspace Products"
        );
        return;
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
        return;
      }
    });
    if (product) {
      setPurItem(product);
    }
    setBegin(false);
    return;
  }, [begin]);

  return (
    <div class="flow-wall">
      <div class="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/products"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Buy Products</h2>
          </div>
          <i class="fa fa-shopping-cart"></i>
        </div>

        {purItem !== "" ? (
          <DownloadProduct
            server={server}
            pur={"pur"}
            view={purItem}
            setFrom={setFrom}
            setShare={setShare}
            setViewItem={setPurItem}
          />
        ) : null}
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search anything here..."
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="photo-bar">
          {products.map((product) => {
            if (product.cate !== "Free") {
              return (
                <div
                  className="photo-card"
                  key={product.id}
                  onClick={() => {
                    setPurItem(product.id);
                  }}
                >
                  <div className="image">
                    <img
                      src={product.icon.length > 0 ? product.icon : "DLM.png"}
                      alt=""
                    />
                  </div>
                  <div className="text-panel">
                    <h4>{product.name}</h4>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div class="body-txt">
          <p>
            Welcome to our Purchase Products section, your destination for
            high-quality items that cater to your every need. Our products are
            meticulously selected to ensure they meet the highest standards of
            quality and value. Books & E-books: Expand your horizons with our
            collection of the latest and greatest reads across various genres
            and topics. Software Solutions: Discover our range of powerful
            software tools designed to boost productivity and creativity.
            Educational Courses: Invest in your future with our expertly crafted
            courses that offer in-depth knowledge and skills. Templates &
            Utilities: Enhance your projects with our premium templates and
            utility tools, tailored to your professional and personal needs.
            Exclusive Accessories: Add a touch of elegance and functionality
            with our selection of unique and stylish accessories. Experience
            seamless shopping with our user-friendly platform, secure payment
            options, and exceptional customer service. Don’t miss out on
            exclusive offers and discounts available only in our purchase
            section.
          </p>
        </div>
        <div class="tags-bar">
          <ul>
            <li>
              <i class="fa fa-tag"></i>News
            </li>
            <li>
              <i class="fa fa-tag"></i>dspace
            </li>
            <li>
              <i class="fa fa-tag"></i>about
            </li>
            <li>
              <i class="fa fa-tag"></i>blog
            </li>
            <li>
              <i class="fa fa-tag"></i>explore
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PurchaseProducts;
