import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import Img from "../assets/duruthu.png";

const AddProducts = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [begin, setBegin] = useState(true);
  const [Add, setAdd] = useState(false);
  const [Del, setDel] = useState(false);

  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [text, setText] = useState("");
  const [Osvr, setOsvr] = useState("");
  const [file, setFile] = useState("");
  const [Size, setSize] = useState("");
  const [Tags, setTags] = useState("");
  const [Prvr, setPrvr] = useState("");
  const [Cate, setCate] = useState("");
  const [Id, setID] = useState(uuidV4());

  const [products, setProducts] = useState([]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/products").then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);

  function getBase642(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setP2(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function setP2(e) {
    setFile(e);
  }
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setP(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function setP(e) {
    setPhoto(e);
  }
  function clearF() {
    let form = document.getElementById("formcd");
    form.reset();
  }

  const upNews = () => {
    if (title.length > 0) {
      if (text.length > 0) {
        if (photo.length > 0) {
          const data = {
            title: title,
            name: name,
            text: text,
            photo: photo,
            osvr: Osvr,
            prid: Id,
            prvr: Prvr,
            size: Size,
            file: file,
            tags: Tags,
            cate: Cate,
          };

          axios.post(server + "/add_product", data).then((response) => {
            if (response.data.status === "success") {
              MsgShow(
                "success",
                "success",
                5000,
                "ok",
                `You have successfully added the Product. Your product will be shown in Products section.`,
                "Product Recorded."
              );
              clearF();
              setBegin(true);
            }
          });
        }
      }
    }
  };
  return (
    <>
      <div className="photo-bar">
        {products.length > 0
          ? products.map((product) => {
              return (
                <div className="photo-card" key={product.id}>
                  <div className="image" style={{ position: "relative" }}>
                    {product.cate === "Free" ? (
                      <div className="free-btn ">
                        <i className="fa fa-download"></i>
                      </div>
                    ) : (
                      <div className="free-btn">
                        <i className="fa fa-shopping-cart"></i>
                      </div>
                    )}
                    <img src={product.icon} alt="" />
                  </div>
                  <div className="text-panel">
                    <h4>@{product.name}</h4>
                  </div>

                  {Del ? (
                    <div
                      className="del-btn"
                      onClick={() => {
                        const data = {
                          id: product.id,
                        };
                        axios
                          .post(server + "/delete_product", data)
                          .then((response) => {
                            if (response.data.status === "success") {
                              MsgShow(
                                "success",
                                "success",
                                5000,
                                "ok",
                                `You have successfully deleted the Product. Your product will be removed from Products section.`,
                                "Product Deleted"
                              );
                              axios
                                .get(server + "/products")
                                .then((response) => {
                                  if (response.data.status === "success") {
                                    setProducts(response.data.data);
                                  }
                                });
                            }
                          });
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
        <div
          className="photo-card"
          onClick={() => {
            if (Add) {
              setAdd(false);
            } else {
              setAdd(true);
            }
          }}
        >
          <div className="image">
            <div className="text-panel">
              <i
                className="fa fa-plus-square"
                style={{ fontSize: "2rem", marginBottom: "10px" }}
              ></i>
              <h4>Product</h4>
            </div>
          </div>
        </div>
      </div>
      {Add ? (
        <form action="" method="post" id="formcd">
          <div className="news-cover">
            <img
              src={photo ? photo : Img}
              alt=""
              onClick={() => {
                document.getElementById("upload").click();
              }}
            />
          </div>
          <h4>Fill Details To Add Product Item</h4>
          <input
            type="file"
            name="upload"
            id="upload"
            onChange={(e) => {
              for (var i = 0; i < e.target.files.length; i++) {
                getBase64(e.target.files[i]);
              }
            }}
          />
          <input
            type="file"
            name="upload"
            id="upload2"
            onChange={(e) => {
              for (var i = 0; i < e.target.files.length; i++) {
                getBase642(e.target.files[i]);
              }
            }}
          />

          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-upload"></i>
              <input
                type="text"
                name="title"
                id="name"
                value={file ? "Your File Is Ready" : "Please select the file"}
                onClick={() => {
                  document.getElementById("upload2").click();
                }}
                readOnly
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-shopping-cart"></i>
              <select
                name="select"
                id="name"
                onChange={(e) => {
                  setCate(e.target.value);
                }}
              >
                <option value="Free">Free</option>
                <option value="Purchase">Purchasable</option>
              </select>
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-rocket"></i>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Product Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-at"></i>
              <input
                type="text"
                name="subtutitle"
                id="name"
                placeholder="Sub Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-key"></i>
              <input
                type="text"
                name="product_id"
                id="name"
                value={Id}
                placeholder="Product ID"
                onClick={() => {
                  setID(uuidV4());
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-desktop"></i>
              <input
                type="text"
                name="Osvr"
                id="name"
                placeholder="OS Versions"
                onChange={(e) => {
                  setOsvr(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-file"></i>
              <input
                type="text"
                name="Prvr"
                id="name"
                placeholder="Product Size"
                onChange={(e) => {
                  setSize(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-star"></i>
              <input
                type="text"
                name="Vr"
                id="name"
                placeholder="Product Version"
                onChange={(e) => {
                  setPrvr(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-tag"></i>
              <input
                type="text"
                name="tags"
                id="name"
                placeholder="Tag Lines"
                onChange={(e) => {
                  setTags(e.target.value);
                }}
              />
            </div>
          </div>
          <div class="flex-bar">
            <div class="input txta">
              <i class="fa fa-flash"></i>
              <textarea
                name="details"
                id="details"
                placeholder="Product Description"
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="btn-bar">
            <div
              className="btn-bx"
              onClick={() => {
                upNews();
              }}
            >
              <i className="fa fa-plus"></i>
              Add Product
            </div>
            <div
              className="btn-bx close"
              onClick={() => {
                if (Add) {
                  setAdd(false);
                } else {
                  setAdd(true);
                }
              }}
            >
              <i className="fa fa-close"></i>
              Cancel Progress
            </div>
          </div>
        </form>
      ) : (
        <div className="btn-bar">
          <div
            className="btn-bx no"
            onClick={() => {
              if (Del) {
                setDel(false);
              } else {
                setDel(true);
              }
            }}
          >
            <i className="fa fa-trash"></i>
            Delete
          </div>

          <div className="btn-bx close" onClick={() => setShow("")}>
            <i className="fa fa-close"></i>
            Close
          </div>
        </div>
      )}
    </>
  );
};

export default AddProducts;
