import { useEffect, useState } from "react";

const ErrorProvider = ({
  error,
  time,
  title,
  message,
  actions,
  mode,
  setmode,
  setError,
  setErrorTime,
  setErrorAction,
}) => {
  const [erm, setErm] = useState(error);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (erm.length > 0) {
      setShow(true);
    }
    if (time >= 500) {
      setTimeout(() => {
        setErm("");
        setError("");
        setmode("normal");
        setShow(false);
      }, time);
    }
    if (localStorage.getItem("dsp-theme") === true) {
      document.getElementsByClassName("card");
    }
  }, [erm.length]);
  const cancelMe = () => {
    setErrorAction("canceled");
    setErm("");
    setError("");
    setShow(false);
    setmode("normal");
  };

  return (
    <div className="wall">
      {show === true ? (
        <div className="card-side">
          <div className="card">
            <div className="header">
              <div
                className="image"
                style={
                  mode === "normal"
                    ? { background: "#82aaff4d" }
                    : mode === "error"
                    ? { background: "#ff3c004d" }
                    : mode === "success"
                    ? { background: "#0e57004d" }
                    : null
                }
              >
                {error === "load" ? (
                  <i className="fa fa-circle-notch fa-spin"></i>
                ) : error === "warning" ? (
                  <i
                    className="fa fa-warning"
                    style={{ color: "orangered" }}
                  ></i>
                ) : error === "success" ? (
                  <i className="fa fa-check" style={{ color: "green" }}></i>
                ) : error === "question" ? (
                  <i className="fa fa-question" style={{ color: "green" }}></i>
                ) : null}
              </div>
              <div className="content">
                <span className="title">{title}</span>
                <p className="message">{message}</p>
              </div>
              {actions === "none" ? null : actions === "yesno" ? (
                <div className="flex-bar">
                  <div
                    className="btn-bx yes"
                    type="button"
                    onClick={() => {
                      setErrorAction("yes");
                    }}
                  >
                    <i className="fa fa-check"></i>
                    Yes
                  </div>
                  <div
                    className="btn-bx no"
                    type="button"
                    onClick={() => {
                      setErrorAction("no");
                    }}
                  >
                    <i className="fa fa-close"></i>
                    No
                  </div>
                </div>
              ) : actions === "ok" ? (
                <div className="flex-bar">
                  <div
                    className="btn-bx"
                    type="button"
                    onClick={() => {
                      cancelMe();
                    }}
                  >
                    <i className="fa fa-thumbs-up"></i>
                    OK
                  </div>
                </div>
              ) : actions === "close" ? (
                <div className="flex-bar">
                  <div
                    className="btn-bx close"
                    type="button"
                    onClick={cancelMe}
                  >
                    <i className="fa fa-close"></i>
                    Close
                  </div>
                </div>
              ) : actions === "yesnoclose" ? (
                <div
                  className="flex-bar"
                  onClick={() => {
                    setErrorAction("accept");
                  }}
                >
                  <div className="btn-bx yes" type="button">
                    <i className="fa fa-check"></i>
                    Accept
                  </div>
                  <div
                    className="btn-bx no"
                    type="button"
                    onClick={() => {
                      setErrorAction("reject");
                    }}
                  >
                    <i className="fa fa-ban"></i>
                    Reject
                  </div>
                  <div
                    className="btn-bx close"
                    type="button"
                    onClick={cancelMe}
                  >
                    <i className="fa fa-close"></i>
                    Close
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default ErrorProvider;
