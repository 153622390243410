import React, { useState } from "react";
import Footer from "./Footer";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { v4 as uuidV4 } from "uuid";

const ForgotPass = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [email, setEmail] = useState("");
  const [Otpmail, setOtpmail] = useState(
    localStorage.getItem("for-email").replace(`"`, "").replace(`"`, "")
  );
  const [otp, setOtp] = useState("");
  const [pass, setPass] = useState("");
  const [passc, setPassC] = useState("");
  const [sent, setSent] = useState(false);
  const [varified, setVarified] = useState(false);
  const [passSet, setPassSet] = useState(false);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const ClearForm = () => {
    let form = document.getElementById("formc");
    form.reset();
  };
  const emailHide = (email) => {
    let one = email.split("@")[0];
    let two = email.split("@")[1];
    let tw1 = two.split(".")[0];
    let three = two.split(".")[1];

    var hiddenOne = "";
    for (var i = 0; i < one.length; i++) {
      if (i > 2 && i < one.length) {
        hiddenOne += "*";
      } else {
        hiddenOne += one[i];
      }
    }
    var hiddenTwo = "";
    for (var i = 0; i < tw1.length; i++) {
      if (i < tw1.length) {
        hiddenTwo += "*";
      } else {
        hiddenTwo += two[i];
      }
    }

    let newStr = hiddenOne + "@" + hiddenTwo + "." + three;
    return newStr;
  };
  return (
    <div className="wall">
      <div className="scroll-wall">
        <div className="post">
          {sent === false ? (
            <form action="" method="post" id="formc">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>Password Recovery</h4>
              {Otpmail ? (
                <p>
                  <br />
                  Please use your following Email Address to continue.
                  <br />
                  <br />
                  <span>{emailHide(Otpmail)}</span>
                </p>
              ) : (
                <p>
                  <br />
                  Please Enter Your Email Address To Continue.
                  <br />
                  <br />
                  <span>Someone@somewhere.com</span>
                </p>
              )}

              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-at"></i>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={emailHide(Otpmail)}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setEmail(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>

              <div class="btn-bar">
                <div
                  class="btn-bx"
                  onClick={() => {
                    if (email.length > 0) {
                      if (email === Otpmail) {
                        const otp = Math.round(
                          Math.floor(100000 + Math.random() * 999999)
                        );
                        const data = {
                          email: email,
                          otp: otp,
                        };
                        axios
                          .post(server + "/forgot_mail", data)
                          .then((response) => {
                            if (response.data.status === "success") {
                              emailjs.init({
                                publicKey: "00YXxLX6rNDtuAHhT",
                              });
                              var template_params = {
                                to_name: email,
                                code: otp,
                                id: uuidV4(),
                              };
                              emailjs
                                .send(
                                  "service_o5xumnk",
                                  "template_hke3jjy",
                                  template_params
                                )
                                .then(
                                  (response) => {
                                    MsgShow(
                                      "success",
                                      "success",
                                      5000,
                                      "ok",
                                      `We have sent an OTP to your email. Please check your inbox.`,
                                      "OTP Sent"
                                    );
                                    setSent(true);
                                    ClearForm();
                                  },
                                  (error) => {
                                    MsgShow(
                                      "error",
                                      "warning",
                                      5000,
                                      "close",
                                      `${error}`,
                                      "OTP Sent Failed"
                                    );
                                  }
                                );
                            }
                          });
                      }
                    }
                  }}
                >
                  <i class="fa fa-send"></i> Send OTP
                </div>
              </div>
            </form>
          ) : varified === false ? (
            <form action="" method="post" id="formc">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>Password Recovery</h4>
              <p>
                <br />
                Please use your 6 digit OTP number.
              </p>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-key"></i>
                  <input
                    type="text"
                    name="otp"
                    maxLength={6}
                    id="username"
                    placeholder="******"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setOtp(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>

              <div class="btn-bar">
                <div
                  class="btn-bx"
                  onClick={() => {
                    if (otp.length > 0) {
                      const data = {
                        email: Otpmail,
                        otp: otp,
                      };
                      axios
                        .post(server + "/get_varify", data)
                        .then((response) => {
                          if (response.data.status === "success") {
                            MsgShow(
                              "success",
                              "success",
                              5000,
                              "ok",
                              `Your account has been verified. Please set your new password.`,
                              "Account Verified"
                            );
                            setVarified(true);
                            ClearForm();
                          }
                        });
                    }
                  }}
                >
                  <i class="fa fa-shield"></i> Varify Account
                </div>
              </div>
            </form>
          ) : passSet === false ? (
            <form action="" method="post" id="formc">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>Password Recovery</h4>
              <p>
                <br />
                Set your new password.
              </p>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-key"></i>
                  <input
                    type="password"
                    name="password"
                    maxLength={16}
                    id="password"
                    placeholder="New Password"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPass(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-key"></i>
                  <input
                    type="password"
                    name="password"
                    maxLength={16}
                    id="password"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPassC(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>

              <div class="btn-bar">
                <div
                  class="btn-bx"
                  onClick={() => {
                    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
                    if (pass.match(passw)) {
                      if (pass === passc) {
                        const data = {
                          email: Otpmail,
                          password: pass,
                        };
                        axios
                          .post(server + "/update_password", data)
                          .then((response) => {
                            if (response.data.status === "success") {
                              MsgShow(
                                "success",
                                "success",
                                50,
                                "close",
                                "You have changed your password successfully. Feel free to use your new password in Dspace. Make a safe journey.",
                                "Password Changed"
                              );
                              ClearForm();
                              window.location.href = "/home";
                            }
                          });
                      } else {
                        MsgShow(
                          "error",
                          "warning",
                          5000,
                          "close",
                          "Your password is not matched with confirming password. Please make a strong password.\nPassword must be more than 8 characters long.\nPassword should contain alpha-numeric characters and a symbol",
                          "Password Unmatched"
                        );
                      }
                    } else {
                      MsgShow(
                        "error",
                        "warning",
                        5000,
                        "close",
                        "Your password is too weak. Please make a strong password.\nPassword must be more than 8 characters long.\nPassword should contain alpha-numeric characters and a symbol",
                        "Password Error"
                      );
                    }
                  }}
                >
                  <i class="fa fa-lock"></i> Set Password
                </div>
              </div>
            </form>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ForgotPass;
