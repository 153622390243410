import QRCodeStyling from "qr-code-styling";
import Icon from "../assets/DLM.png";
import React, { useEffect } from "react";

const QRGenerator = ({ keyNo, setKn }) => {
  const options = {
    type: "canvas",
    shape: "square",
    width: 200,
    margin: 10,
    height: 200,
    data: "https://www.dspace.icu/get_file?id=" + keyNo,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "H" },
    imageOptions: {
      saveAsBlob: true,
      hideBackgroundDots: true,
      imageSize: 0.3,
    },
    dotsOptions: {
      type: "rounded",
      roundSize: false,
      gradient: {
        type: "linear",
        rotation: 0.7853981633974483,
        colorStops: [
          { offset: 0, color: "#000000" },
          { offset: 1, color: "#000000" },
        ],
      },
    },
    backgroundOptions: { round: 0, color: "#ffffff" },
    image: Icon,
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#666666",
        color2: "#666666",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#000000" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  };

  const qrCode = new QRCodeStyling(options);

  useEffect(() => {
    if (keyNo != null) {
      if (document.getElementById("canvas")) {
        document.getElementById("canvas").innerHTML = "";
        qrCode.append(document.getElementById("canvas"));
      }
    }
  }, [keyNo]);

  return (
    <div className="qr-code">
      <div id="canvas"></div>
    </div>
  );
};
export default QRGenerator;
