import React, { useEffect, useState } from "react";
import Icon from "../assets/gallery.gif";
import { Link } from "react-router-dom";
import axios from "axios";

const ViewAlbumMedia = ({ server, album, setview, setCategory }) => {
  const [photos, setphotos] = useState([]);
  const [begin, setBegin] = useState(true);

  useEffect(() => {
    axios.get(server + "/photos?key=" + album).then((response) => {
      if (response.data.status === "success") {
        setphotos(response.data.data);
        return setBegin(false);
      }
    });
  }, [begin]);
  return (
    <div className="photo-bar">
      {photos.map((photo) => {
        return (
          <div
            className="photo-card"
            key={photo.id}
            onClick={() => {
              setview(photo.pid);
              setCategory("image");
            }}
          >
            <div className="image">
              <img src={photo.file ? photo.file : Icon} alt="" />
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ViewAlbumMedia;
