import React, { useEffect, useState } from "react";
import Audio from "../assets/dd.mp3";
import axios from "axios";
import { Link } from "react-router-dom";

const ViewMedia = ({
  view,
  icon,
  category,
  setCategory,
  setViewFile,
  setShare,
  setFrom,
  server,
}) => {
  const [file, setfile] = useState([]);
  const [begin, setbegin] = useState(true);

  useEffect(() => {
    if (category === "image") {
      axios.get(server + "/file?key=" + view).then((response) => {
        if (response.data.status === "success") {
          setfile(response.data.data);
          return setbegin(false);
        }
      });
    }
    if (category === "video") {
      axios.get(server + "/video_file?key=" + view).then((response) => {
        if (response.data.status === "success") {
          setfile(response.data.data);
          return setbegin(false);
        }
      });
    }
    if (category === "audio") {
      axios.get(server + "/audio_file?key=" + view).then((response) => {
        if (response.data.status === "success") {
          setfile(response.data.data);
          return setbegin(false);
        }
      });
    }
  }, [begin]);
  return (
    <div className="media-file">
      <div className="title-bar">
        <h4>
          {category === "image" ? (
            <i className="fa fa-image"></i>
          ) : category === "video" ? (
            <i className="fa fa-film"></i>
          ) : category === "audio" ? (
            <i className="fa fa-headphones"></i>
          ) : null}
          {file.map((fi) => {
            return fi.name;
          })}
        </h4>
      </div>

      <div className="screen">
        {category === "image" ? (
          <div id="media" className="mrg">
            {file.map((fi) => {
              return <img key={fi.id} src={fi.file ? fi.file : icon} alt="" />;
            })}
          </div>
        ) : category === "video" ? (
          <div id="media">
            {file.map((fi) => {
              return (
                <video
                  id="video"
                  preload="auto"
                  key={fi.id}
                  type="video/mp4"
                  src={fi.file}
                ></video>
              );
            })}

            <div className="controls">
              <div
                className="btn-cir"
                onClick={() => {
                  let video = document.getElementById("video");
                  video.play();
                }}
              >
                <i className="fa fa-play"></i>
              </div>
              <div
                className="btn-cir"
                onClick={() => {
                  let video = document.getElementById("video");
                  video.pause();
                }}
              >
                <i className="fa fa-pause"></i>
              </div>

              <div
                className="btn-cir"
                onClick={() => {
                  let video = document.getElementById("video");
                  if (video.muted === true) {
                    video.muted = false;
                  } else {
                    video.muted = true;
                  }
                }}
              >
                <i className="fa fa-volume-up"></i>
              </div>
              <div
                className="btn-cir"
                onClick={() => {
                  let elem = document.getElementById("video");

                  if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                  } else if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen();
                  } else if (elem.msRequestFullscreen) {
                    /* IE11 */
                    elem.msRequestFullscreen();
                  }
                }}
              >
                <i className="fas fa-expand"></i>
              </div>
            </div>
          </div>
        ) : category === "audio" ? (
          <div id="media">
            <div className="gram-phone">
              <div id="disk" className="disk ">
                <img src={icon} alt="" />
              </div>
            </div>
            <div
              className="album-art"
              style={{
                backgroundImage: `url(${icon})`,
              }}
            ></div>
            {file.map((fi) => {
              return (
                <audio
                  key={fi.id}
                  id="audio"
                  preload="auto"
                  type="audio/mp3"
                  src={fi.file ? fi.file : Audio}
                  onEnded={() => {
                    let spin = document.getElementById("disk");
                    spin.classList.remove("fa-spin");
                  }}
                ></audio>
              );
            })}

            <div className="controls">
              <div
                className="btn-cir"
                onClick={() => {
                  let video = document.getElementById("audio");
                  video.play();
                  let spin = document.getElementById("disk");
                  spin.classList.add("fa-spin");
                }}
              >
                <i className="fa fa-play"></i>
              </div>
              <div
                className="btn-cir"
                onClick={() => {
                  let video = document.getElementById("audio");
                  video.pause();
                  let spin = document.getElementById("disk");
                  spin.classList.remove("fa-spin");
                }}
              >
                <i className="fa fa-pause"></i>
              </div>

              <div
                className="btn-cir"
                onClick={() => {
                  let video = document.getElementById("audio");
                  if (video.muted === true) {
                    video.muted = false;
                  } else {
                    video.muted = true;
                  }
                }}
              >
                <i className="fa fa-volume-up"></i>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="buttons">
        <div className="flex">
          {file.map((fi) => {
            if (fi.type.includes("image")) {
              return (
                <Link
                  className="btn-cir"
                  key={fi.id}
                  to={"/download_media?fid=" + fi.pid + "&type=" + fi.type}
                >
                  <i className="fa fa-download"></i>
                </Link>
              );
            }
            if (fi.type.includes("audio")) {
              return (
                <Link
                  className="btn-cir"
                  key={fi.id}
                  to={"/download_media?fid=" + fi.aid + "&type=" + fi.type}
                >
                  <i className="fa fa-download"></i>
                </Link>
              );
            }
            if (fi.type.includes("video")) {
              return (
                <Link
                  className="btn-cir"
                  key={fi.id}
                  to={"/download_media?fid=" + fi.vid + "&type=" + fi.type}
                >
                  <i className="fa fa-download"></i>
                </Link>
              );
            }
          })}
          {file.map((fi) => {
            if (fi.type.includes("image")) {
              return (
                <div
                  className="btn-cir"
                  onClick={() => {
                    setShare(
                      "https://www.dspace.icu/download_media?fid=" +
                        fi.pid +
                        "&type=" +
                        fi.type
                    );
                    setFrom("media");
                  }}
                >
                  <i className="fa fa-share"></i>
                </div>
              );
            }
            if (fi.type.includes("audio")) {
              return (
                <div
                  className="btn-cir"
                  onClick={() => {
                    setShare(
                      "https://www.dspace.icu/download_media?fid=" +
                        fi.aid +
                        "&type=" +
                        fi.type
                    );
                    setFrom("media");
                  }}
                >
                  <i className="fa fa-share"></i>
                </div>
              );
            }
            if (fi.type.includes("video")) {
              return (
                <div
                  className="btn-cir"
                  onClick={() => {
                    setShare(
                      "https://www.dspace.icu/download_media?fid=" +
                        fi.vid +
                        "&type=" +
                        fi.type
                    );
                    setFrom("media");
                  }}
                >
                  <i className="fa fa-share"></i>
                </div>
              );
            }
          })}

          <div className="btn-cir">
            <i className="fa fa-bookmark"></i>
          </div>
          <div className="btn-cir">
            <i className="fa fa-star"></i>
          </div>
        </div>
        <div
          className="btn-cir close"
          onClick={() => {
            setCategory("");
            setViewFile("");
          }}
        >
          <i className="fa fa-close"></i>
        </div>
      </div>
    </div>
  );
};
export default ViewMedia;
