import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import Icon from "../assets/DLM.png";
import { toPng } from "html-to-image";

const QRshare = ({ addr, setShare, from }) => {
  const options = {
    type: "canvas",
    shape: "square",
    width: 300,
    margin: 10,
    height: 300,
    data: addr,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "H" },
    imageOptions: {
      saveAsBlob: true,
      hideBackgroundDots: true,
      imageSize: 0.3,
    },
    dotsOptions: {
      type: "rounded",
      roundSize: false,
      gradient: {
        type: "linear",
        rotation: 0.7853981633974483,
        colorStops: [
          { offset: 0, color: "#000000" },
          { offset: 1, color: "#000000" },
        ],
      },
    },
    backgroundOptions: { round: 0, color: "#ffffff" },
    image: Icon,
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#666666",
        color2: "#666666",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#000000" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  };

  const qrCode = new QRCodeStyling(options);
  useEffect(() => {
    if (addr != null) {
      if (document.getElementById("canvas")) {
        document.getElementById("canvas").innerHTML = "";
        qrCode.append(document.getElementById("canvas"));
      }
    }
  }, [addr]);
  const contentRef = useRef(null);

  const handleCapture = () => {
    const node = contentRef.current;
    toPng(node, { cacheBust: true }).then((dataUrl) => {
      const link = document.createElement("a");
      link.download = "Share.png";
      link.href = dataUrl;
      link.click();
      navigator.clipboard.writeText(addr);
      setShare("");
    });
  };

  return (
    <div
      className="qr-share"
      onClick={() => {
        handleCapture();
      }}
    >
      <div className="card" ref={contentRef}>
        <div className="text">
          <h4>Dspace {from}</h4>
        </div>
        <div className="qr-code" id="canvas"></div>
        <div className="text">
          <p>Scan This card to Reach</p>
        </div>
      </div>
    </div>
  );
};

export default QRshare;
