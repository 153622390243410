import axios from "axios";
import React, { useEffect, useState } from "react";

const EventItemDisplay = ({
  view,
  setViewItem,
  setShare,
  setFrom,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [event, setEvent] = useState([]);
  const [begin, setBegin] = useState(true);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/get_event?key=" + view).then((response) => {
      if (response.data.status === "success") {
        setEvent(response.data.data);
        MsgShow(
          "normal",
          "load",
          2500,
          "",
          "Please wait a moment.",
          "Loading Event..."
        );
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
      }
      setBegin(false);
    });
  }, [begin]);

  return (
    <div className="event-view">
      {event.length > 0
        ? event.map((eve) => {
            return (
              <div className="event-bar" key={eve.id}>
                <div className="title">
                  <div className="icon">
                    <img src={eve.pic} alt="" />
                  </div>
                </div>
                <div className="details">
                  <p>
                    <h4>{eve.title}</h4>
                  </p>

                  <p>
                    <span>Details :</span>
                    <br /> {eve.deta}
                  </p>
                  <p>
                    <span>Date :</span> {eve.date}
                  </p>
                  <p>
                    <span>Posted By :</span> @{eve.name}
                  </p>
                </div>

                <div className="btn-bar">
                  <div
                    className="btn-bx"
                    onClick={() => {
                      setShare(
                        "https://www.dspace.icu/view_events?id=" + eve.id
                      );
                      setFrom("events");
                    }}
                  >
                    <i className="fa fa-link"></i>
                    Share
                  </div>
                  <div
                    className="btn-bx close"
                    onClick={() => {
                      const queryString = window.location.search;
                      const urlParams = new URLSearchParams(queryString);
                      const id = urlParams.get("id");
                      if (id) {
                        window.history.back();
                        setViewItem("");
                      } else {
                        setViewItem("");
                      }
                    }}
                  >
                    <i className="fa fa-close"></i>
                    close
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default EventItemDisplay;
