import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const Ratings = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  const [rate, setRate] = useState(
    localStorage.getItem("rate-user") ? localStorage.getItem("rate-user") : 0
  );
  const [begin, setBegin] = useState(true);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    if (!localStorage.getItem("rate-user")) {
      const data = {
        user_id: user.uid,
      };
      axios.post(server + "/get_rates_user", data).then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem("rate-user", response.data.data[0].rate);
          setRate(response.data.data[0].rate);
        } else {
          localStorage.setItem("rate-user", 0);
          setRate(0);
        }
      });
    }
    setBegin(false);
  }, [begin]);
  useEffect(() => {
    let star1 = document.getElementById("star1");
    let star2 = document.getElementById("star2");
    let star3 = document.getElementById("star3");
    let star4 = document.getElementById("star4");
    let star5 = document.getElementById("star5");

    if (rate >= 1) {
      star1.classList.add("rated");
    } else {
      star1.classList.remove("rated");
    }
    if (rate >= 2) {
      star2.classList.add("rated");
    } else {
      star2.classList.remove("rated");
    }
    if (rate >= 3) {
      star3.classList.add("rated");
    } else {
      star3.classList.remove("rated");
    }
    if (rate >= 4) {
      star4.classList.add("rated");
    } else {
      star4.classList.remove("rated");
    }
    if (rate >= 5) {
      star5.classList.add("rated");
    } else {
      star5.classList.remove("rated");
    }
  }, [rate]);

  const handleRating = (e) => {
    const data = {
      rating: e,
      user: user.user,
      uid: user.uid,
      photo: user.photo,
    };
    axios.post(server + "/rate", data).then((response) => {
      if (response.data.status === "success") {
        setRate(e);
        localStorage.setItem("rate-user", e);
        if (e > 4) {
          MsgShow(
            "success",
            "success",
            50,
            "ok",
            "You have successfully given 5 stars to the DLM. Your user experience is greatly appreciated.",
            "Rating Recorded"
          );
        } else {
          if (e === 4) {
            MsgShow(
              "success",
              "success",
              50,
              "ok",
              `Thanks for your ${e} stars rate. You can always change your ratings as your user experiance. We are so sad about lossing a star.`,
              "Ratings Recorded"
            );
          } else {
            MsgShow(
              "success",
              "success",
              50,
              "ok",
              `Thanks for your ${e} stars rate. You can always change your ratings as your user experiance. We are so sad about lossing ${
                5 - e
              } stars.`,
              "Ratings Recorded"
            );
          }
        }
      }
    });
  };

  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/explore"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Ratings</h2>
          </div>

          <i class="fa fa-star"></i>
        </div>
        <div className="post">
          <div className="logo">
            <img src="DLM.png" alt="" />
          </div>
          <h4>Rate us.</h4>
          <div className="rate-stars">
            <div
              className="star"
              id="star5"
              onClick={() => {
                handleRating(5);
              }}
            >
              <i className="fa fa-star"></i>
            </div>
            <div
              className="star"
              id="star4"
              onClick={() => {
                handleRating(4);
              }}
            >
              <i className="fa fa-star"></i>
            </div>
            <div
              className="star"
              id="star3"
              onClick={() => {
                handleRating(3);
              }}
            >
              <i className="fa fa-star"></i>
            </div>
            <div
              className="star"
              id="star2"
              onClick={() => {
                handleRating(2);
              }}
            >
              <i className="fa fa-star"></i>
            </div>
            <div
              className="star"
              id="star1"
              onClick={() => {
                handleRating(1);
              }}
            >
              <i className="fa fa-star"></i>
            </div>
          </div>
          <p>
            You gave us <span> 0{rate} </span> {rate > 1 ? "stars" : "star"}.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Ratings;
