import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Author from "../assets/author.jpg";
import { Link } from "react-router-dom";
import axios from "axios";

function User({
  setLogin,
  login,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) {
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  const [begin, setBegin] = useState(true);
  const [Adbegin, setAdBegin] = useState(false);
  const [photo, setPhoto] = useState("");
  const [Username, setUsername] = useState("");
  const [checkB, setCheckB] = useState(false);
  const [Pass, setPass] = useState("");
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return handleUpload(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  var theme_in_use = "";

  if (localStorage.getItem("dsp-theme") === "false") {
    theme_in_use = "Light Theme";
  } else {
    theme_in_use = "Dark Theme";
  }
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  function handleUpload(e) {
    var data = {
      email: user.email,
      photo: e,
    };
    axios.post(server + "/up_pp", data).then((response) => {
      if (response.data.status === "success") {
        user.photo = e;
        localStorage.setItem("dsp-user", JSON.stringify(user));
        MsgShow(
          "success",
          "success",
          50,
          "close",
          "Your profile photo was updated nice and clean. If you want to change again, Make a good choice.",
          "Profile Photo Updated"
        );
      } else {
        console.log(response.data.msg);
      }
    });
  }
  useEffect(() => {
    var data = {
      user: user.uid,
    };
    axios.post(server + "/get_user_data", data).then((response) => {
      // handle success
      if (response.data.status === "success") {
        localStorage.setItem("dsp-user", JSON.stringify(response.data.data[0]));
        localStorage.setItem(
          "for-email",
          JSON.stringify(response.data.data[0].email)
        );
      }
    });
    setBegin(false);
  }, [begin]);

  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/home"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Profile</h2>
          </div>
          <i class="fa fa-user-circle"></i>
        </div>
        <div className="post">
          <form
            action=""
            method="post"
            id="myForm"
            enctype="multipart/form-data"
          >
            <div
              class="user"
              onClick={() => {
                document.getElementById("upload").click();
              }}
            >
              <div className="img">
                <img src={user.photo} alt="Logo" />
              </div>
            </div>
            <h4>Your Account</h4>
            <p>
              <span>User : </span>
              {user.user}
            </p>
            <p>
              <span>N.I.C : </span>
              {user.nic}
            </p>
            <p>
              <span>Email : </span>
              {user.email}
            </p>
            <p>
              <span>Varified : </span>
              {user.varified !== "YES" ? (
                <Link to={"/varify"}>Varify Now</Link>
              ) : (
                "Done!"
              )}
            </p>
            <p>
              <span>Theme : </span>
              {theme_in_use}
            </p>
            <p>
              <br />
              <span className="green-f">
                Protected By End to End Encryption{" "}
                <i className="fa fa-check-circle"></i>
              </span>
            </p>
            <input
              type="file"
              name="upload"
              id="upload"
              onChange={(e) => {
                getBase64(e.target.files[0]);
              }}
            />
          </form>
          {Adbegin ? (
            <form action="" method="post" id="formc">
              <div class="logo">
                <img src="DLM.png" alt="Logo" />
              </div>
              <h4>administrator sign in</h4>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-at"></i>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Admin Username"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setUsername(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar">
                <div class="input">
                  <i class="fa fa-lock"></i>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    maxLength={8}
                    placeholder="Admin Password"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPass(e.target.value);
                      }
                    }}
                    required
                  />
                </div>
              </div>
              <div class="flex-bar wrap-bar">
                <div class="remember">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    onChange={(e) => {
                      setCheckB(e.target.checked);
                    }}
                  />
                  <label for="remember">
                    <p>Remember me</p>
                  </label>
                </div>
                <div class="forgot">
                  <i className="fa fa-lock"></i>
                  <Link to="/forgot_admin_password">
                    Forgot Administrative Password?
                  </Link>
                </div>
              </div>

              <div class="btn-bar">
                <div
                  class="btn-bx yes"
                  onClick={() => {
                    if (Username === user.adid) {
                      if (Pass === user.adps) {
                        if (checkB === true) {
                          localStorage.setItem("dsp-admin", Username);
                        }
                        MsgShow(
                          "success",
                          "success",
                          50,
                          "close",
                          `Welcome Back to Administrators Dashboard. Enjoy ${Username}!`,
                          "Signed in"
                        );
                        window.location.href = `/admin_dashboard?id=${Username}`;
                      }
                    }
                  }}
                >
                  Go to Dashboard&nbsp;&nbsp;
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </form>
          ) : null}

          <div className="line-bar ">
            <div className="line-back"></div>

            <div className="line cntr maxw">
              {user.role === "admin" ? (
                <div
                  className="btn-wrap"
                  onClick={() => {
                    if (localStorage.getItem("dsp-admin")) {
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "close",
                        `Welcome Back to Administrators Dashboard. Enjoy ${localStorage.getItem(
                          "dsp-admin"
                        )}!`,
                        "Signed in"
                      );
                      window.location.href = `/admin_dashboard?id=${localStorage.getItem(
                        "dsp-admin"
                      )}`;
                    } else {
                      if (Adbegin) {
                        setAdBegin(false);
                      } else {
                        setAdBegin(true);
                      }
                    }
                  }}
                >
                  <div className="btn ">
                    <i class="fa fa-user-shield"></i>
                  </div>
                </div>
              ) : null}

              <div
                className="btn-wrap"
                onClick={() => {
                  //setMode("privacy");
                }}
              >
                <div className="btn ">
                  <i class="fa fa-cog fa-spin"></i>
                </div>
              </div>
              <div
                className="btn-wrap"
                onClick={() => {
                  //setMode("cookie policy");
                }}
              >
                <div className="btn ">
                  <i class="fa fa-repeat fa-spin"></i>
                </div>
              </div>
              <div
                className="btn-wrap"
                onClick={() => {
                  setLogin("false");
                  localStorage.setItem("dsp-login-status", "false");
                  localStorage.removeItem("dsp-user");
                  localStorage.removeItem("dsp-admin");
                  localStorage.removeItem("rate-user");
                }}
              >
                <div className="btn">
                  <i class="fa fa-sign-out"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default User;
