import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/DLM.png";
import { Link } from "react-router-dom";
import EventItemDisplay from "./EventItemDisplay";
import axios from "axios";

const ViewEvents = ({
  setShare,
  setFrom,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  act,
  setAction,
  server,
}) => {
  const [show, setShow] = useState("photo");
  const [events, setEvents] = useState([]);
  const [viewItem, setViewItem] = useState("");
  const [begin, setBegin] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const event = urlParams.get("id");

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/events").then((response) => {
      if (response.data.status === "success") {
        setEvents(response.data.data);
        MsgShow(
          "normal",
          "load",
          2500,
          "",
          "We are loading our events. Wait a moment.",
          "Please Wait..."
        );
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
      }
      if (event) {
        setViewItem(event);
      }
      setBegin(false);
      return;
    });
  }, [begin]);

  return (
    <div class="flow-wall">
      <div class="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/events"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Events</h2>
          </div>
          <i class="fa fa-calendar"></i>
        </div>
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search anything here..."
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        {viewItem !== "" ? (
          <EventItemDisplay
            Icon={Icon}
            view={viewItem}
            setViewItem={setViewItem}
            setShare={setShare}
            setFrom={setFrom}
            setmode={setmode}
            setError={setError}
            setErrorTime={setErrorTime}
            setErrorActions={setErrorActions}
            setErrorMessage={setErrorMessage}
            setErrorTitle={setErrorTitle}
            act={act}
            setAction={setAction}
            server={server}
          />
        ) : (
          <div className="photo-bar">
            {events.length > 0
              ? events.map((event) => {
                  return (
                    <div
                      className="photo-card"
                      key={event.id}
                      onClick={() => {
                        setViewItem(event.id);
                      }}
                    >
                      <div className="image">
                        <img src={event.pic} alt="" />
                      </div>
                      <div className="text-panel">
                        <h4>@{event.name}</h4>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        )}

        <div class="body-txt">
          <p>
            Our Events Wall is your gateway to staying connected and engaged
            with everything happening at dspace.lk.Whether you're looking to
            attend, revisit, or simply stay informed, our Events Wall is the
            place to be. Thank you for joining us at dspace.lkEvents Wall. Enjoy
            exploring and staying connected!
          </p>
        </div>
        <div class="tags-bar">
          <ul>
            <li>
              <i class="fa fa-tag"></i>Audios
            </li>
            <li>
              <i class="fa fa-tag"></i>Videos
            </li>
            <li>
              <i class="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i class="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i class="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ViewEvents;
