import axios from "axios";
import React, { useEffect, useState } from "react";

const RecoverFiles = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [begin, setBegin] = useState(true);
  const [Add, setAdd] = useState(false);
  const [Del, setDel] = useState(false);
  const [fid, setfid] = useState("");
  const [uid, setuid] = useState("");
  const [photo, setPhoto] = useState("");
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/get_files_admin").then((response) => {
      if (response.data.status === "success") {
        setFiles(response.data.data);
      }
    });
    setBegin(false);
  }, [begin]);

  const FilesC = (e) => {
    var files = JSON.parse(e);
    return files.length;
  };
  return (
    <>
      <form action="" method="post" id="formcd">
        <h4>Find files by File ID or User ID</h4>
        <div className="flex-bar">
          <div className="input">
            <i className="fa fa-key"></i>
            <input
              type="text"
              name="userid"
              id="name uid"
              placeholder="User ID"
              onChange={(e) => {
                setuid(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex-bar">
          <div className="input">
            <i className="fa fa-key"></i>
            <input
              type="text"
              name="fileid"
              id="name fid"
              placeholder="File ID"
              onChange={(e) => {
                setfid(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="btn-bar">
          <div
            className="btn-bx"
            onClick={() => {
              if (uid.length > 0) {
                setAdd(true);
              }
              if (fid.length > 0) {
                setAdd(true);
              }
            }}
          >
            <i className="fa fa-search"></i>
            Search Files
          </div>
        </div>
      </form>
      <div className="grid-bar center-bar">
        {files.length > 0
          ? files.map((file) => {
              if (Add) {
                if (file.uid === uid) {
                  return (
                    <div className="photo-card" key={file.id}>
                      <div className="image">
                        <i
                          className="fa fa-file"
                          style={{ fontSize: "2rem", marginBottom: "10px" }}
                        ></i>
                      </div>
                      <div className="text-panel">
                        <h4>
                          {file.files
                            ? "0" + FilesC(file.files) + " Files"
                            : null}
                        </h4>
                      </div>
                    </div>
                  );
                } else {
                  if (file.fid === fid) {
                    return (
                      <div className="photo-card" key={file.id}>
                        <div className="image">
                          <i
                            className="fa fa-file"
                            style={{ fontSize: "2rem", marginBottom: "10px" }}
                          ></i>
                        </div>
                        <div className="text-panel">
                          <h4>
                            {file.files
                              ? "0" + FilesC(file.files) + " Files"
                              : null}
                          </h4>
                        </div>
                      </div>
                    );
                  }
                }
              }
            })
          : null}
        <div className="photo-card" onClick={() => {}}>
          <div className="image">
            <div className="text-panel">
              <i
                className="fa fa-archive"
                style={{ fontSize: "2rem", marginBottom: "10px" }}
              ></i>
              <h4>Files</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RecoverFiles;
