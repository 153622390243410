import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/download.gif";
import { Link } from "react-router-dom";
import axios from "axios";

const DownloadMedia = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [Pastetext, setPastetext] = useState("");
  const [files, setFiles] = useState([]);
  const [begin, setbegin] = useState(true);
  const [beginDown, setbeginDown] = useState(false);
  const [doneGen, setdoneGen] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const media = urlParams.get("fid");
  const media_type = urlParams.get("type");

  document.addEventListener("keydown", function (event) {
    if (event.key === "Backspace") {
      setPastetext("");
    }
  });
  function enableButn(value) {
    setbeginDown(true);
    // Converting string to required date format
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + value);
    // To call defined fuction every second
    let x = setInterval(function () {
      // Getting current time in required format
      let now = new Date().getTime();

      // Calculating the difference
      let t = deadline - now;

      // Getting value of days, hours, minutes, seconds
      let seconds = Math.floor((t % (1000 * 60)) / 1000);

      // Output the remaining time
      document.getElementById("reduced").innerHTML =
        "Please wait " +
        (seconds > 9
          ? seconds + " seconds to create the download link"
          : "0" + seconds + " seconds to create the download link");
      document.getElementById("pgr").style.width =
        Math.floor(((value - seconds) / value) * 100) + "%";
      // Output for over time
      if (t < 0) {
        clearInterval(x);
        setdoneGen(true);
        document.getElementById("reduced").innerHTML =
          "Your download link has been generated successfully. Click the below button to download.";
      }
    }, 1000);
  }
  const getFiles = (key) => {
    axios.get(server + "/file?key=" + key).then((response) => {
      if (response.data.status === "success") {
        setFiles(response.data.data);
        return;
      } else {
        console.log(response.data.msg);
      }
    });
  };
  const getaudioFiles = (key) => {
    axios.get(server + "/audio_file?key=" + key).then((response) => {
      if (response.data.status === "success") {
        setFiles(response.data.data);
        return;
      } else {
        console.log(response.data.msg);
      }
    });
  };
  const getvideoFiles = (key) => {
    axios.get(server + "/video_file?key=" + key).then((response) => {
      if (response.data.status === "success") {
        setFiles(response.data.data);
        return;
      } else {
        console.log(response.data.msg);
      }
    });
  };
  useEffect(() => {
    if (media_type) {
      if (media_type.includes("image")) {
        axios.get(server + "/file?key=" + media).then((response) => {
          if (response.data.status === "success") {
            setFiles(response.data.data);
            setPastetext(media);
          }
        });
      } else if (media_type.includes("video")) {
        axios.get(server + "/video_file?key=" + media).then((response) => {
          if (response.data.status === "success") {
            setFiles(response.data.data);
            setPastetext(media);
          }
        });
      } else if (media_type.includes("audio")) {
        axios.get(server + "/audio_file?key=" + media).then((response) => {
          if (response.data.status === "success") {
            setFiles(response.data.data);
            setPastetext(media);
          }
        });
      } else {
        MsgShow(
          "error",
          "warning",
          50,
          "close",
          "No file found with the given key or the file type is not supported.",
          "Invalid request"
        );
      }
    }
    setbegin(false);
  }, [begin]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  const DownloadMediaFile = () => {
    var a = document.createElement("a");
    a.href = files[0].file;
    a.download = files[0].name;
    a.click();
    setPastetext("");
    setFiles([]);
    return window.history.back();
  };

  function fetchRequest(formData) {
    fetch("http://api.qrserver.com/v1/read-qr-code/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (!result[0].symbol[0].data) {
          MsgShow(
            "error",
            "warning",
            50,
            "close",
            "No QR code has found on your image. Copy your key text or scan the correct QR Code to get your files.",
            "Wrong image format"
          );
          setPastetext("");
          document.getElementById("search").value = "";
        } else {
          let text = result[0].symbol[0].data;
          if (text.includes("download_media")) {
            window.location.href = text;
          } else {
            MsgShow(
              "error",
              "warning",
              50,
              "close",
              "No key code has found on your QR image. Copy your key text or scan the correct QR Code to get your files.",
              "No Key Found"
            );
            setPastetext("");
            document.getElementById("search").value = "";
          }
        }
      });
  }

  function DecodeImg(image) {
    let formData = new FormData();
    formData.append("file", image);
    fetchRequest(formData);
  }
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/media"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Download</h2>
          </div>
          <i class="fa fa-cloud-download"></i>
        </div>

        <div className="line-bar">
          <div className="line-back"></div>
          <div className="text-a bgtext">
            <h4 style={{ textTransform: "capitalize" }}>
              {media ? (
                media_type.includes("image") ? (
                  <i class="fa fa-image"></i>
                ) : media_type.includes("audio") ? (
                  <i class="fa fa-headphones"></i>
                ) : media_type.includes("video") ? (
                  <i class="fa fa-film"></i>
                ) : (
                  <i class="fa fa-warning"></i>
                )
              ) : (
                <i class="fa fa-warning"></i>
              )}
              {media
                ? media_type.includes("image")
                  ? "Image"
                  : media_type.includes("audio")
                  ? "Audio"
                  : media_type.includes("video")
                  ? "Video"
                  : "No File"
                : "No File"}
            </h4>
          </div>
          <div className="line">
            <div
              className="btn-wrap"
              onClick={() => {
                document.getElementById("upload").click();
              }}
            >
              <div className="btn">
                <i class="fa fa-camera"></i>
              </div>
            </div>

            <div
              className="btn-wrap"
              onClick={() => {
                document.getElementById("upload").click();
              }}
            >
              <div className="btn">
                <i class="fa fa-image"></i>
              </div>
            </div>
            <div
              className="btn-wrap"
              onClick={() => {
                document.getElementById("search").click();
              }}
            >
              <div className="btn">
                <i class="fa fa-brush"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="paste-bar">
          {Pastetext.length > 0 ? (
            <div className="btn-bar">
              <div
                className="btn-bx close"
                onClick={() => {
                  setPastetext("");
                  setFiles([]);
                  return window.history.back();
                }}
              >
                <i className="fa fa-close"></i>
                Cancel Process
              </div>
            </div>
          ) : (
            <div className="paste-put">
              <div className="icon">
                <i className="fa fa-paste"></i>
              </div>
              <input
                type="text"
                name="link-address"
                id="search"
                placeholder="Paste Link here..."
                value={Pastetext.length > 0 ? Pastetext : null}
                onClick={(e) => {
                  setTimeout(async () => {
                    const text = await navigator.clipboard.readText();
                    if (text.includes("download_media")) {
                      window.location.href = text;
                    } else {
                      MsgShow(
                        "error",
                        "warning",
                        50,
                        "close",
                        "No key code has found on your clipboard. Copy your key text or scan the QR Code to get your files.",
                        "No Key Found"
                      );
                      setPastetext("");
                      setFiles([]);
                      return;
                    }
                  }, 20);
                }}
              />
            </div>
          )}
        </div>
        <div className="grid-bar center-bar">
          <div className="empty-card">
            {files.length > 0 ? (
              files.map((file) => {
                return (
                  <>
                    <div className="down-bar">
                      <div className="pg-bar">
                        <div className="pg" id="pgr"></div>
                      </div>
                      <p id="reduced">Click to generate the link</p>
                    </div>
                    <div className="btn-bar">
                      {!beginDown ? (
                        <div
                          className="btn-bx close"
                          onClick={() => {
                            enableButn(17);
                          }}
                        >
                          <i className="fa fa-play"></i>
                          Start Download
                        </div>
                      ) : doneGen ? (
                        <div
                          className="btn-bx yes"
                          onClick={() => {
                            DownloadMediaFile();
                          }}
                        >
                          <i className="fa fa-cloud-download"></i>
                          Download Now
                        </div>
                      ) : (
                        <div className="btn-bx no">
                          <i className="fa fa-circle-notch fa-spin"></i>
                          Generating Link
                        </div>
                      )}
                    </div>
                  </>
                );
              })
            ) : (
              <div className="btn-bar">
                <div className="cover">
                  <img src={Icon} alt="" srcset="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="body-txt">
          <p>
            Welcome to our Download Center! Here, you'll find a curated
            selection of files available for download, including software
            updates, multimedia assets, and informative resources. Each file is
            accompanied by a convenient QR code for quick and easy access on
            your mobile devices. Simply scan the code with your phone to
            instantly download the content you need.
          </p>
        </div>

        <input
          type="file"
          name="upload"
          id="upload"
          multiple
          onChange={(e) => {
            if (e.target.files) {
              DecodeImg(e.target.files[0]);
              e.target.value = "";
            }
          }}
        />
      </div>
      <Footer />
    </div>
  );
};
export default DownloadMedia;
