import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import QRGenerator from "./QRGenerator";
import Scan from "../assets/scan.gif";
import Upload from "../assets/upload.gif";
import axios from "axios";

function GetStart({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  act,
  server,
}) {
  const [mode, setMode] = useState("send");
  const [Pastetext, setPastetext] = useState("");
  const [files, setFiles] = useState([]);
  const [added, setAdded] = useState([]);
  const [key, setKey] = useState(uuidV4());
  const [Dkey, setDkey] = useState("");
  const [Password, setPassword] = useState("");
  const [fp, setFp] = useState("");
  const [fshow, setFshow] = useState(false);
  const [begin, setBegin] = useState(true);
  const [fpe, setFpe] = useState("");
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  document.addEventListener("keydown", function (event) {
    if (event.key === "Backspace") {
      setPastetext("");
    }
  });
  useEffect(() => {
    if (user.uid.length > 0) {
      axios.get(server + "/get_user_files?key=" + user.uid).then((response) => {
        if (response.data.status === "success") {
          var djk = response.data.data;
          let imf = JSON.parse(djk);
          setAdded(imf);
        } else {
          console.log(response.data.msg);
        }
        setBegin(false);
      });
    }
  }, [begin]);
  if (act === "ok") {
    setErrorMessage("");
    setError("");
    setmode("normal");
  }
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  let passLabel = document.getElementById("checkpass");
  if (document.getElementById("checkpass")) {
    passLabel.classList.remove("fa-check");
    passLabel.classList.add("fa-close");
    passLabel.style.color = "orangered";
  }
  function fetchRequest(formData) {
    fetch("http://api.qrserver.com/v1/read-qr-code/", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (!result[0].symbol[0].data) {
          MsgShow(
            "error",
            "warning",
            5000,
            "close",
            "There is something wrong with your QR code.",
            "Fetching Error"
          );
          setDkey("");
          setFiles([]);
        } else {
          let paramString = result[0].symbol[0].data.split("?")[1];
          let queryString = new URLSearchParams(paramString);
          for (let pair of queryString.entries()) {
            getFiles(pair[1]);
            setDkey(pair[1]);
            setPastetext(pair[1]);
            MsgShow(
              "success",
              "success",
              5000,
              "ok",
              "Your QR code has been accepted and waiting for download your files.",
              "Action Completed"
            );
            return;
          }
        }
      });
  }

  function DecodeImg(image) {
    let formData = new FormData();
    formData.append("file", image);
    fetchRequest(formData);
  }

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setFilesList(file.name, file.type, file.size, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  let kk = [...files];
  function setFilesList(name, type, size, file) {
    kk.push({
      name: name,
      type: type,
      size: size,
      file: file,
    });
    if (kk.length > 0) {
      MsgShow(
        "success",
        "success",
        5000,
        "ok",
        `You have added ${kk.length} files to upload.`,
        "Action Completed"
      );
      return setFiles(kk);
    }
  }

  const setPass = () => {
    let input = document.getElementById("password");
    if (input.value.length === 8) {
      setPassword(input.value);
      MsgShow(
        "success",
        "success",
        50,
        "close",
        `Your password successfully added to the encryption data. Please writedown and give it to the users who want to get the file(s).`,
        "Password Added"
      );
    } else {
      MsgShow(
        "error",
        "warning",
        50,
        "close",
        `Your password is 'Null' or lessthan 8 characters in length. Please update your 8 characters length password.`,
        "Password Error"
      );
    }
  };
  const deleteFiles = (val) => {
    axios.get(server + "/delete_files_up?key=" + val).then((response) => {
      if (response.data.status === "success") {
        MsgShow(
          "success",
          "success",
          50,
          "ok",
          `File ${val} has been deleted from your files list.`,
          "Action Completed"
        );
        setBegin(true);
      } else {
        MsgShow(
          "error",
          "warning",
          50,
          "close",
          `An error occured while we deleting your files. Please try again in few moments.`,
          "Action Declined"
        );
      }
    });
  };
  function closedownloadingProcess() {
    MsgShow(
      "success",
      "success",
      5000,
      "close",
      `Previous session has been aborted. Select a new QR Key file to begging process.`,
      "Session Aborted"
    );
    setFiles([]);
    setPastetext("");
    setDkey("");
    setFp("");
    setFshow(false);
    setFpe("");
  }

  const sendFiles = () => {
    const data = {
      pass: Password,
      uid: user.uid,
      fid: key,
      files: JSON.stringify(files),
    };
    axios.post(server + "/send_files", data).then((response) => {
      if (response.data.status === "success") {
        MsgShow(
          "success",
          "success",
          50,
          "close",
          "Your files had been uploaded nice and clean. You can share your QR code with others to give them access to files.",
          "Files uploaded"
        );
        setBegin(true);
        setPassword("");
        setFiles([]);
        setKey(uuidV4());
      } else {
        console.log(response.data.msg);
      }
    });
  };

  //getfIles
  const getFiles = (key) => {
    axios.get(server + "/get_files?key=" + key).then((response) => {
      if (response.data.status === "success") {
        let response_got = response.data.data[0].files;

        //if password?
        var pass = response.data.data[0].pass;
        if (pass.length === 8) {
          MsgShow(
            "normal",
            "warning",
            5000,
            "close",
            `This file QR is encrypted with a password. Do you have it? You need it to unlock the content.`,
            "Protected File"
          );
          setFp(pass);
          var parsed_data = JSON.parse(response_got);
          setFiles(parsed_data);
          return;
        }
      } else {
        console.log(response.data.msg);
      }
    });
  };

  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/home"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Get Start</h2>
          </div>
          {mode === "send" ? (
            <i class="fa fa-send"></i>
          ) : (
            <i class="fa fa-qrcode"></i>
          )}
        </div>

        <div className="line-bar">
          <div className="line-back"></div>
          <div className="text-a bgtext">
            <h4 style={{ textTransform: "capitalize" }}>
              {mode === "send" ? (
                <i class="fa fa-send"></i>
              ) : (
                <i class="fa fa-qrcode"></i>
              )}
              {mode}
            </h4>
          </div>
          {mode === "send" ? (
            <div className="line">
              {Password.length === 8 ? (
                <div
                  className="btn-wrap"
                  onClick={() => {
                    document.getElementById("upload").click();
                  }}
                >
                  <div className="btn">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
              ) : (
                <div className="btn-wrap" onClick={setPass}>
                  <div className="btn">
                    <i class="fa fa-key"></i>
                  </div>
                </div>
              )}
              {files.length > 0 ? (
                <div
                  className="btn-wrap"
                  onClick={() => {
                    sendFiles();
                  }}
                >
                  <div className="btn">
                    <i class="fa fa-save"></i>
                  </div>
                </div>
              ) : null}
              {files.length > 0 ? (
                <div
                  className="btn-wrap"
                  onClick={() => {
                    MsgShow(
                      "success",
                      "success",
                      5000,
                      "close",
                      `Your process has been canceled. Let's begging another process. Remember?, Set a password first.`,
                      "Session Closed"
                    );
                    setFiles([]);
                    setPassword("");
                    setKey(uuidV4());
                  }}
                >
                  <div className="btn">
                    <i class="fa fa-repeat"></i>
                  </div>
                </div>
              ) : null}

              <div
                className="btn-wrap"
                onClick={() => {
                  setMode("recieve");
                  setDkey("");
                  setPassword("");
                  setFiles([]);
                }}
              >
                <div className="btn">
                  <i class="fa fa-qrcode"></i>
                </div>
              </div>
            </div>
          ) : mode === "recieve" ? (
            <div className="line">
              {Dkey.length > 0 ? (
                <>
                  <div
                    className="btn-wrap"
                    onClick={() => {
                      sessionStorage.setItem("files", JSON.stringify(files));
                      sessionStorage.setItem("zip-name", "DZip.zip");
                      window.location.href = "/download_zip";
                    }}
                  >
                    <div className="btn">
                      <i class="fa fa-archive"></i>
                    </div>
                  </div>
                  <div
                    className="btn-wrap"
                    onClick={() => {
                      closedownloadingProcess();
                    }}
                  >
                    <div className="btn">
                      <i class="fa fa-repeat"></i>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="btn-wrap"
                    onClick={() => {
                      document.getElementById("upload").click();
                    }}
                  >
                    <div className="btn">
                      <i class="fa fa-camera"></i>
                    </div>
                  </div>
                  <div
                    className="btn-wrap"
                    onClick={() => {
                      document.getElementById("upload").click();
                    }}
                  >
                    <div className="btn">
                      <i class="fa fa-image"></i>
                    </div>
                  </div>
                  <div
                    className="btn-wrap"
                    onClick={() => {
                      document.getElementById("search").click();
                    }}
                  >
                    <div className="btn">
                      <i class="fa fa-brush"></i>
                    </div>
                  </div>
                </>
              )}
              <div
                className="btn-wrap"
                onClick={() => {
                  setPastetext("");
                  setMode("send");
                  setDkey("");
                  setKey(uuidV4());
                  setFiles([]);
                }}
              >
                <div className="btn">
                  <i class="fa fa-send"></i>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {mode === "send" ? (
          files.length > 0 ? (
            <div class="body-center">
              <h4>Your Key : </h4>
              <QRGenerator keyNo={key} setKn={setKey} />
            </div>
          ) : null
        ) : null}

        {mode === "send" ? (
          <>
            {Password.length < 8 ? (
              <div className="btn-bar">
                <div className="pass-bar">
                  <h4>Create A Strong Password</h4>
                  <div className="pass-put">
                    <i className="fa fa-lock"></i>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      maxLength={8}
                      placeholder="Create Password"
                      onChange={(e) => {
                        if (e.target.value) {
                          setFpe(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {files.length > 0 ? (
              <div className="grid-bar">
                {files.map((file) => {
                  let type = file.type;
                  return (
                    <div className="grid-card" key={file.name}>
                      <div className="image">
                        {file.type.includes("image") ? (
                          <i className="fa fa-image"></i>
                        ) : file.type.includes("audio") ? (
                          <i className="fa fa-headphones"></i>
                        ) : file.type.includes("json") ? (
                          <i className="fa fa-code"></i>
                        ) : file.type.includes("text") ? (
                          <i className="fa fa-list"></i>
                        ) : file.type.includes("video") ? (
                          <i className="fa fa-film"></i>
                        ) : file.type.includes("zip") ? (
                          <i className="fa fa-archive"></i>
                        ) : file.name.includes(".rar") ? (
                          <i className="fa fa-archive"></i>
                        ) : (
                          <i className="fa fa-warning"></i>
                        )}
                      </div>
                      <div className="text-panel">
                        <h4>
                          {file.name.length > 15
                            ? file.name.substring(0, 15) + "..."
                            : file.name}
                        </h4>
                        <p>{Math.round(file.size / 1024) + " KB"}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="grid-bar center-bar">
                <div className="empty-card">
                  <div className="btn-bar">
                    <div className="cover">
                      <img src={Upload} alt="" srcset="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="table-bar">
              <h4>Your Uploads List</h4>
              <div className="table-cr">
                {added.length > 0
                  ? added.map((val) => {
                      var files = JSON.parse(val.files);
                      return (
                        <>
                          <div className="table-row" key={val.id}>
                            <div className="title">
                              <p className="btn-cir-bar">
                                <span
                                  className="btn-cir"
                                  onClick={() => {
                                    //ShareQR(val.fid);
                                  }}
                                >
                                  <i className="fa fa-qrcode"></i>
                                </span>
                                <span
                                  className="btn-cir"
                                  onClick={() => {
                                    deleteFiles(val.fid);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </span>
                              </p>
                            </div>
                            <div className="details">
                              <p>DZip{val.id}.zip </p>
                              <br />
                              <span> Files Password : {val.pass}</span>
                              <br />
                              <span> Files Containing : {files.length}</span>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
            <input
              type="file"
              name="upload"
              id="upload"
              multiple
              onChange={(e) => {
                for (var i = 0; i < e.target.files.length; i++) {
                  getBase64(e.target.files[i]);
                }
              }}
            />
          </>
        ) : (
          <>
            {Dkey.length > 0 ? (
              <div class="body-center">
                <h4>Your Downloads Ready </h4>
                <QRGenerator keyNo={Dkey} setKn={setKey} />
              </div>
            ) : null}
            {mode === "recieve" ? (
              <div className="paste-bar">
                {Pastetext.length > 0 ? (
                  <div className="btn-bar">
                    <div
                      className="btn-bx no"
                      onClick={() => {
                        setPastetext("");
                        setDkey("");
                        setFp("");
                        setFshow(false);
                      }}
                    >
                      <i className="fa fa-search"></i>
                      Clear Pasted Address
                    </div>
                  </div>
                ) : (
                  <div className="paste-put">
                    <div className="icon">
                      <i className="fa fa-paste"></i>
                    </div>
                    <input
                      type="text"
                      name="link-address"
                      id="search"
                      placeholder="Paste your key here..."
                      value={Pastetext.length > 0 ? Pastetext : ""}
                      onClick={(e) => {
                        setTimeout(async () => {
                          const text = await navigator.clipboard.readText();
                          if (
                            text.includes("https://www.dspace.icu/get_file?id=")
                          ) {
                            let paramString = text.split("?")[1];
                            let queryString = new URLSearchParams(paramString);
                            for (let pair of queryString.entries()) {
                              getFiles(pair[1]);
                              setDkey(pair[1]);
                              setPastetext(pair[1]);
                              return;
                            }
                          } else {
                            MsgShow(
                              "error",
                              "warning",
                              50,
                              "close",
                              "No key code has found on your clipboard. Copy your key text or scan the QR Code to get your files.",
                              "No Key Found"
                            );
                            setPastetext("");
                            setDkey("");
                            setFiles([]);
                            return;
                          }
                        }, 20);
                      }}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {fshow ? (
              files.length > 0 ? (
                <div className="grid-bar">
                  {files.map((file) => {
                    return (
                      <div className="grid-card" key={uuidV4()}>
                        <div className="image">
                          {file.type.includes("image") ? (
                            <i className="fa fa-image"></i>
                          ) : file.type.includes("audio") ? (
                            <i className="fa fa-headphones"></i>
                          ) : file.type.includes("json") ? (
                            <i className="fa fa-code"></i>
                          ) : file.type.includes("text") ? (
                            <i className="fa fa-list"></i>
                          ) : file.type.includes("video") ? (
                            <i className="fa fa-film"></i>
                          ) : file.type.includes("zip") ? (
                            <i className="fa fa-archive"></i>
                          ) : file.name.includes(".rar") ? (
                            <i className="fa fa-archive"></i>
                          ) : (
                            <i className="fa fa-warning"></i>
                          )}
                        </div>
                        <div className="text-panel">
                          <h4>
                            {file.name.length > 10
                              ? file.name.substring(0, 10) + "..."
                              : file.name}
                          </h4>
                          <p>{Math.round(file.size / 1024)} KB</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="grid-bar center-bar">
                  <div className="empty-card">
                    <div className="btn-bar">
                      <div className="cover">
                        <img src={Scan} alt="" srcset="" />
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : fp.length > 0 ? (
              <div className="grid-bar center-bar">
                <div className="empty-card">
                  <div className="btn-bar">
                    <div className="pass-bar">
                      <h4>Enter Password</h4>
                      <div className="pass-put">
                        <i className="fa fa-lock"></i>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          maxLength={8}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            if (e.target.value) {
                              setFpe(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {fpe === fp ? (
                      <div
                        className="btn-bx yes"
                        onClick={() => {
                          if (fpe === fp) {
                            setFshow(true);
                          }
                        }}
                      >
                        <i className="fa fa-unlock"></i> Unlock
                      </div>
                    ) : (
                      <p className="ppass">Password Not Valid!</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid-bar center-bar">
                <div className="empty-card">
                  <div className="btn-bar">
                    <div className="cover">
                      <img src={Scan} alt="" srcset="" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <input
              type="file"
              name="upload"
              id="upload"
              onChange={(e) => {
                if (e.target.files) {
                  DecodeImg(e.target.files[0]);
                  e.target.value = "";
                }
              }}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
export default GetStart;
