import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Products() {
  return (
    <div className="wall">
      <div class="post">
        <div class="logo">
          <img src="DLM.png" alt="Logo" />
        </div>
        <h4>Products and status</h4>
        <p>
          At Dspace.lk, we pride ourselves on offering a diverse range of
          cutting-edge multimedia products designed to meet the unique needs of
          our clients. From state-of-the-art software solutions to visually
          stunning digital content, our products are crafted with precision and
          creativity. Dive into our product offerings and discover how we can
          help you achieve your digital goals with excellence and innovation.
        </p>
        <div class="btn-bar">
          <Link class="btn-bx" to={"/view_products"}>
            <i class="fa fa-search"></i> products
          </Link>
          <Link class="btn-bx" to={"/purchase_products"}>
            <i class="fa fa-shopping-cart"></i> purchase
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Products;
