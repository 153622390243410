import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/icon.png";
import { Link } from "react-router-dom";
import DownloadProduct from "./DownloadProduct";
import axios from "axios";

const ViewProducts = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
  setShare,
  setFrom,
}) => {
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  const [products, setProducts] = useState([]);
  const [viewItem, setViewItem] = useState("");
  const [begin, setBegin] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("id");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.get(server + "/products").then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
        MsgShow(
          "normal",
          "load",
          2500,
          "",
          "We are loading our stuff. Wait a moment.",
          "Please Wait..."
        );
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
      }
    });
    if (product) {
      setViewItem(product);
    }
    setBegin(false);
    return;
  }, [begin]);

  return (
    <div class="flow-wall">
      <div class="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/products"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Free Products</h2>
          </div>
          <i class="fa fa-briefcase"></i>
        </div>

        {viewItem !== "" ? (
          <DownloadProduct
            server={server}
            view={viewItem}
            setShare={setShare}
            setFrom={setFrom}
            setViewItem={setViewItem}
          />
        ) : null}
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search anything here..."
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="photo-bar">
          {products.map((product) => {
            if (product.cate === "Free") {
              return (
                <div
                  className="photo-card"
                  key={product.id}
                  onClick={() => {
                    setViewItem(product.id);
                  }}
                >
                  <div className="image">
                    <img src={product.icon} alt="" />
                  </div>
                  <div className="text-panel">
                    <h4>{product.name}</h4>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div class="body-txt">
          <p>
            Welcome to our Free Products section, where we believe everyone
            deserves access to top-quality resources without a price tag.
            Explore our curated collection of complimentary offerings designed
            to enhance your experience and add value to your life. E-books: Dive
            into a world of knowledge with our extensive range of free e-books
            across various genres and topics. Software Trials: Test-drive our
            premium software with no commitment. Experience all the features and
            benefits at no cost. Templates: From business documents to creative
            projects, download professional-grade templates to simplify your
            tasks. Courses & Tutorials: Unlock new skills and enhance your
            expertise with our free online courses and tutorials. Tools &
            Utilities: Access a variety of handy tools and utilities to
            streamline your daily activities. Check back often as we regularly
            update our free offerings. Enjoy the freedom to explore without
            spending a dime.
          </p>
        </div>
        <div class="tags-bar">
          <ul>
            <li>
              <i class="fa fa-tag"></i>News
            </li>
            <li>
              <i class="fa fa-tag"></i>dspace
            </li>
            <li>
              <i class="fa fa-tag"></i>about
            </li>
            <li>
              <i class="fa fa-tag"></i>blog
            </li>
            <li>
              <i class="fa fa-tag"></i>explore
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ViewProducts;
